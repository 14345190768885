angular.module('ControllersModule')
  .controller('MobileNotificationsController', function ($interval, $scope, $rootScope, AuthenticationFactory, AccountFactory, NotificationsFactory, $timeout) {

  // Notifications Menu
  // -------------------------------------------------------------
  $scope.loadingNotifications = true;
  $scope.showNotifications = false;
  $scope.notificationsLength = 0;
  $scope.notifications = [];

  function getNotifications() {
    NotificationsFactory.getNotifications().then(getNotificationsSuccess, getNotificationsFailure);
  }

  var getNotificationsSuccess = function(data) {
    $scope.notifications = $scope.notifications.concat(data.result);

    if(data.headers['results-next']) {
      NotificationsFactory.getNotifications(data.headers['results-next']).then(getNotificationsSuccess, getNotificationsFailure);
    } else {
      $scope.loadingNotificationsError = false;
      $scope.loadingNotifications = false;
      $scope.notificationsLength = $scope.notifications.length;
      markNotificationsRead();
    }
  };

  var getNotificationsFailure = function() {
    $scope.loadingNotifications = false;
    $scope.loadingNotificationsError = true;
  };

  function markNotificationsRead() {
    for (var i=0; i<$scope.notifications.length; i++) {
      NotificationsFactory.markNotificationRead($scope.notifications[i].id);
    }
  }

  $scope.dismissNotification = function(notification, event){
    event.stopPropagation();
    removeNotificationById(notification.id);
    $scope.notificationsLength--;
    NotificationsFactory.dismissNotification(notification.id);
  };

  var removeNotificationById = function(id) {
    for(var i=0;i<$scope.notifications.length;i++) {
      if($scope.notifications[i].id === id) {
        $scope.notifications.splice(i, 1);
      }
    }
  }

  $scope.dismissAllNotifications = function() {
    event.stopPropagation();
    for(var i=0;i<$scope.notifications.length;i++) {
      NotificationsFactory.dismissNotification($scope.notifications[i].id);
    }
    $scope.notifications = [];
    $scope.notificationsLength = 0;
  }

  getNotifications();
});