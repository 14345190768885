angular.module('ControllersModule')
  .controller('ApiController', function ($scope, $rootScope, DialogFactory, AccountFactory) {

  $scope.loadingPage = true;
  $scope.loadingError = false;

  $scope.routeAppRequired = function(event) {
    if($rootScope.user.currentapp === null) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, DialogFactory.closeDialog);
    }
  }

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    if ($scope.account.products.includes('sda')) {
      $scope.sdaEnabled = true;
    }
    $scope.loadingPage = false;
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }
});