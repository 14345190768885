angular.module('ControllersModule')
  .controller('NavController', function ($window, $interval, LoginFactory, $route, $location, $scope, $rootScope, AuthenticationFactory, AccountFactory, NotificationsFactory, HttpPendingRequestsService, DialogFactory, SDKTypeFactory) {

  // https://tc39.github.io/ecma262/#sec-array.prototype.includes
  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function(searchElement, fromIndex) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
        var o = Object(this);
        var len = o.length >>> 0;
        if (len === 0) {
          return false;
        }
        var n = fromIndex | 0;
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }
        while (k < len) {
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          k++;
        }
        return false;
      }
    });
  }

  var appsToGet = 0;
  var keysToGet = 0;
  $rootScope.navOpen = false;
  $scope.appsHolder = [];
  $scope.account;
  $scope.accountState;
  $scope.showAccountBanner = false;
  $scope.daysRemaining = 0;
  $scope.accountExpirationDate = 0;

  $rootScope.$watch('nav', function () {
    if($rootScope.nav) {
      init();
    }
  })

  function init() {
    appsToGet = 0;
    keysToGet = 0;
    $scope.showAccountBanner = false;
    $scope.appsHolder = [];
    $scope.loadingApps = true;
    $scope.showApps = false;
    $scope.daysRemaining = 0;

    if($rootScope.user.trial === 'true') {
      $scope.accountState = 'trial';
    } else if($rootScope.user.expiredtrial === 'true') {
      $scope.accountState = 'expired-trial';
    } else {
      $scope.accountState = 'live';
    }

    getApps();
    getNotifications();
    AccountFactory.getAccount().then(checkToShowAccountStateBanner, function(){})
  }

  function checkToShowAccountStateBanner(data) {
    $scope.account = data.result[0];
    if($rootScope.user.isadmin === 'true') {
      $scope.accountExpirationDate = data.result[0].expiration_date;
      $scope.daysRemaining = daysLeftInTrial();

      if($scope.daysRemaining !== 999) {
        if($scope.accountState === 'trial' || $scope.accountState === 'expired-trial') {
          $scope.showAccountBanner = true;
        } else if($scope.accountState === 'live' && $scope.daysRemaining <= 30) {
          $scope.showAccountBanner = true;
        }
      }
    }
  }

  // Apps Menu
  // -------------------------------------------------------------
  function daysLeftInTrial() {
    var expirationDate = $window.moment.utc($scope.accountExpirationDate);
    var todaysDate = $window.moment(new Date());
    var daysLeft = expirationDate.diff(todaysDate, 'days');
    if(daysLeft < 1) {
      daysLeft = 0;
    }
    return daysLeft;
  }

  function getApps() {
    LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
  }

  function getUserDataSuccess(data) {
    $scope.appsList = data.result.apps;
    appsToGet = $scope.appsList.length;
    keysToGet = $scope.appsList.length;

    for(var i=0;i<$scope.appsList.length;i++) {
      AccountFactory.getAppV2($scope.appsList[i].id).then(getAppSuccess, getAppFailure);
    }

    if($scope.appsList.length == 0) {
      //no apps available for user, clear root current app
      AuthenticationFactory.setCurrentApp(null);
      $scope.loadingApps = false;
    }
  }

  function getUserDataFailure(data) {
    $scope.loadingApps = false;
    $scope.loadingAppsError = true;
  }

  function getAppSuccess(data) {
    $scope.appsHolder.push(data.result);
    appsToGet--;
    if(appsToGet === 0) {
      $scope.appsHolder.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      $scope.selectedApp = $rootScope.user.currentapp;
      for(var i=0;i<$scope.appsList.length;i++) {
        LoginFactory.getAppKey($scope.appsList[i].id).then(getAppKeySuccess, getAppKeyFailure);
      }
    }
  }

  function getAppFailure(data) {
    $scope.loadingApps = false;
    $scope.loadingAppsError = true;
  }

  function getAppKeySuccess(data) {
    keysToGet--;
    if(keysToGet === 0) {
      $scope.loadingApps = false;
      $rootScope.apps = $scope.appsHolder;
    }
  }

  function getAppKeyFailure(data) {
    $scope.loadingApps = false;
    $scope.loadingAppsError = true;
  }

  $scope.toggleAppsDropDown = function(close) {
    $scope.toggleAllMobileNavigationDropDowns();
    if(close) {
      $scope.showApps = false;
      return;
    }
    $scope.showApps = !$scope.showApps;
  }

  $scope.clickOutsideAppsDropDown = function() {
    if(!$rootScope.navOpen) {
      $scope.showApps = false;
    }
  }

  $scope.changeApp = function(app) {
    if(app !== null) {
      if($rootScope.user.currentapp === null || $rootScope.user.currentapp.name !== app.name) {
        HttpPendingRequestsService.cancelAll();
        AuthenticationFactory.setCurrentApp(JSON.stringify(app));
        $scope.toggleAppsDropDown(true);
        if($location.path().slice(42,44) === '/') {
          $location.url('/apps/'+$rootScope.user.currentapp.id);
        } else if($location.path().slice(42,53) === '/analytics/') {
          $location.url('/apps/'+$rootScope.user.currentapp.id+'/analytics/');
        } else if($location.path().slice(42,59) === '/sdk-tokens/') {
          $location.url('/apps/'+$rootScope.user.currentapp.id+'/sdk-tokens/');
        } else if($location.path().slice(42,65) === '/sdk-tokens/create') {
          $location.url('/apps/'+$rootScope.user.currentapp.id+'/sdk-tokens/create');
        } else if($location.path().slice(42,59) === '/api-tokens/') {
          $location.url('/apps/'+$rootScope.user.currentapp.id+'/api-tokens/');
        } else if($location.path().slice(0,14) === '/tone-creator/') {
          $route.reload();
        }
      }
    }
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;

  // Notifications Menu
  // -------------------------------------------------------------
  $scope.loadingNotifications = true;
  $scope.showNotifications = false;
  $scope.unreadNotifications = 0;
  $scope.notificationsLength = 0;
  $scope.notificationsHolder = [];
  $scope.notifications = [];
  var notificationsToDismiss = 0;
  var dismissingNotifications = false;
  var notificationsInterval = $interval(getNotifications, 10000);

  function getNotifications() {
    $scope.notificationsHolder = [];
    if(!dismissingNotifications) {
      if($rootScope.nav) {
        NotificationsFactory.getNotifications().then(getNotificationsSuccess, getNotificationsFailure);
      } else {
        $interval.cancel(notificationsInterval);
      }
    }
  }

  var getNotificationsSuccess = function(data) {
    $scope.notificationsHolder = $scope.notificationsHolder.concat(data.result);

    if(data.headers['results-next']) {
      NotificationsFactory.getNotifications(data.headers['results-next']).then(getNotificationsSuccess, getNotificationsFailure);
    } else {
      $scope.notifications = $scope.notificationsHolder;
      $scope.loadingNotificationsError = false;
      var unreadCount = 0;
      $scope.loadingNotifications = false;
      for (var i=0; i<$scope.notifications.length; i++) {
        if(!$scope.notifications[i].is_read) {
          unreadCount++;
        }
      }
      $scope.unreadNotifications = unreadCount;
      $scope.notificationsLength = $scope.notifications.length;
      if($scope.showNotifications) {
        if($scope.unreadNotifications > 0) {
          $scope.markNotificationsRead();
        }
      }
    }
  };

  var getNotificationsFailure = function() {
    $scope.loadingNotifications = false;
    $scope.loadingNotificationsError = true;
  };

  $scope.toggleNotificationsDropDown = function(close) {
    if(($scope.unreadNotifications > 0) && (!close)){
      $scope.markNotificationsRead();
    }
    if(close) {
      $scope.showNotifications = false;
      return;
    }
    $scope.showNotifications = !$scope.showNotifications;
  }

  $scope.clickOutsideNotificationsDropDown = function() {
    if(!$rootScope.navOpen) {
      $scope.showNotifications = false;
    }
  }

  $scope.markNotificationsRead = function() {
    for (var i=0; i<$scope.notifications.length; i++) {
      NotificationsFactory.markNotificationRead($scope.notifications[i].id);
    }
    $scope.unreadNotifications = 0;
  }

  $scope.dismissNotification = function(notification, event){
    event.stopPropagation();
    removeNotificationById(notification.id);
    $scope.notificationsLength--;
    NotificationsFactory.dismissNotification(notification.id);
  };

  var removeNotificationById = function(id) {
    for(var i=0;i<$scope.notifications.length;i++) {
      if($scope.notifications[i].id === id) {
        $scope.notifications.splice(i, 1);
      }
    }
  }

  $scope.dismissAllNotifications = function() {
    event.stopPropagation();
    notificationsToDismiss = $scope.notifications.length;
    for(var i=0;i<$scope.notifications.length;i++) {
      NotificationsFactory.dismissNotification($scope.notifications[i].id).then(dismissNotificationSuccess, dismissNotificationFailure);
    }
    $scope.notifications = [];
    $scope.notificationsLength = 0;
  }

  function dismissNotificationSuccess() {
    notificationsToDismiss--;
    if(notificationsToDismiss === 0) {
      dismissingNotifications = false;
    }
  }

  function dismissNotificationFailure() {
    notificationsToDismiss--;
    if(notificationsToDismiss === 0) {
      dismissingNotifications = false;
    }
  }

  // Settings Menu
  // -------------------------------------------------------------

  $scope.showSettings = false;

  $scope.toggleSettingsDropDown = function(close) {
    if(close) {
      $scope.showSettings = false;
      return;
    }
    $scope.showSettings = !$scope.showSettings;
  }

  $scope.clickOutsideSettingsDropDown = function() {
    if(!$rootScope.navOpen) {
      $scope.showSettings = false;
    }
  }

  $scope.logout = function(event) {
    $scope.closeAllMobileMenus();
    $rootScope.navOpen = false;
    event.preventDefault();
    AuthenticationFactory.logout();
  }

  // Navigation Menu
  // -------------------------------------------------------------
  $scope.toggleNavigationItemMobileDropDown = function(event) {
    if($rootScope.navOpen) {
      event.preventDefault();

      var toggle = !angular.element(event.currentTarget).hasClass('open');

      $scope.toggleAppsDropDown(true);

      if(toggle) {
        angular.element(event.currentTarget).addClass('open');
      } else {
        angular.element(event.currentTarget).removeClass('open');
      }
    }
  }

  $scope.toggleAllMobileNavigationDropDowns = function() {
    if($rootScope.navOpen) {
      angular.element(document.querySelectorAll('.main-menu li')).removeClass('open');
      angular.element(document.querySelectorAll('.mobile-menu div')).removeClass('open');
    }
  }

  $scope.closeAllMobileMenus = function(event) {
    $scope.toggleAppsDropDown(true);
    $scope.toggleNotificationsDropDown(true);
    $scope.toggleSettingsDropDown(true);
  }

  $scope.checkAppOffline = function(event) {
    if($rootScope.user.currentapp && $rootScope.user.currentapp.type === 'offline') {
      event.preventDefault();
    }
  }

  $scope.routeAppRequired = function(event) {
    if($rootScope.user.currentapp === null) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, DialogFactory.closeDialog);
    }
  }

  $scope.routeAdminRequired = function(event) {
    if($rootScope.user.isadmin !== 'true' && !$rootScope.user.user_type.includes('developer')) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must be an admin or a developer to access this page.', false, DialogFactory.closeDialog);
    }
  }

  // Page width change handler
  // -------------------------------------------------------------

  // http://stackoverflow.com/questions/21626357/angularjs-event-on-window-innerwidth-size-change
  $(window).resize(function() {
    $scope.$apply(function() {
       if(window.innerWidth > 800) {
        $scope.closeAllMobileMenus();
        $rootScope.navOpen = false;
       }
    });
  });
});
