angular.module('FactoriesModule')
  .factory('LoginFactory', function ($http, $rootScope, HttpUtilitiesFactory) {

  function forgotPassword(username) {
    var url = new URI($rootScope.authURL);
    url = url.segment('password/reset').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      username: username
    }));
  };

  function login(credentials) {
    var url = new URI($rootScope.authURL);
    url = url.segment('login').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      username: credentials.username,
      password: credentials.password
    }));
  };

  function getUserData() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/me').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function agreeV2(initials) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('auth/customer-agreement/accept').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      initials: initials
    }));
  };

  function getApp(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getAppKey(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/keys').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function editAppKey(appid, keyid, key) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+appid+'/keys/'+keyid).toString();
    return HttpUtilitiesFactory._transformResponseData($http.put(url, key));
  };

  return {
    forgotPassword: forgotPassword,
    login: login,
    getUserData: getUserData,
    agreeV2: agreeV2,
    getApp: getApp,
    getAppKey: getAppKey,
    editAppKey: editAppKey
  }
});
