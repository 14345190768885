angular.module('FactoriesModule')
  .factory('LastActivityFactory', function ($window) {

    /*
    Get a user-friendly display of a token's date of last activity.
    */
    var lastActivityDisplay = function(token) {
      // Check if token has never been used
      if (token.last_activity === null) {
          return 'never';
      }

      // Check if token was used within the last day
      var today = $window.moment.utc().startOf('day');
      var last_activity = $window.moment.utc(token.last_activity);
      var diff_days = last_activity.diff(today, 'days');
      if (diff_days >= 0) {
          return 'today';
      }

      // Get user-friendly representation of relative time
      return $window.moment.duration(diff_days, 'days').humanize(true);
    }

    return {
      lastActivityDisplay: lastActivityDisplay,
    }
});
