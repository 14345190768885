angular.module('FactoriesModule')
  .factory('ToneCreatorFactory', function ($http, HttpUtilitiesFactory) {

    var tonesServiceURI = new URI(tonesServiceURL);
    tonesServiceURI = tonesServiceURI.toString();

    const textPayloadRegex = /^[\x0A\x20-\x7E]+$/;
    const dataPayloadRegex = /^(?:[a-fA-F0-9]{2})+$/;
    var result;
    var length;

    function validateToneProperties(toneProperties, valid, invalid) {
      if (typeof toneProperties.payload === 'undefined') {
        invalid('Please enter a payload.');
        return;
      } else if (toneProperties.payload.length === 0) {
        invalid('Please enter a payload.');
        return;
      }

      switch (toneProperties.type) {
        case 'text':
          length = toneProperties.payload.length;
          result = textPayloadRegex.test(toneProperties.payload);
          if (!result) {
            invalid('Invalid payload for text type payload. Text payloads must be ASCII characters only.');
            return;
          }
          break;
        case 'data':
          length = toneProperties.payload.length / 2;
          result = dataPayloadRegex.test(toneProperties.payload);
          if (!result) {
            invalid('Invalid payload for data type payload. Data payloads must be a hex string representation of bytes. E.g. "a1b2c3".');
            return;
          }
          break;
      }

      const pkabPayloadLimit = 3000;
      const standardPayloadLimit = 255;

      const profileInfoTable = {
        zone66: {
          name: 'Zone66',
          limit: standardPayloadLimit
        },
        point1000: {
          name: 'Point1000',
          limit: pkabPayloadLimit
        },
        point2000: {
          name: 'Point2000',
          limit: pkabPayloadLimit
        },
        pkab2: {
          name: 'PKAB 2.0',
          limit: pkabPayloadLimit
        },
        pkab2_wideband: {
          name: 'PKAB 2.0 Wideband',
          limit: pkabPayloadLimit
        },
        standard: {
          name: 'Standard',
          limit: standardPayloadLimit
        },
        standard2: {
          name: 'Standard 2.0',
          limit: standardPayloadLimit
        },
        standard2_wideband: {
          name: 'Standard 2.0 Wideband',
          limit: standardPayloadLimit
        },
        compression: {
          name: 'Compression',
          limit: standardPayloadLimit
        }
      };

      const profileInfo = profileInfoTable[toneProperties.profile];
      var errorMessage;

      if (length > profileInfo.limit) {
        errorMessage = profileInfo.name + ' payload limit is ' + profileInfo.limit + ' bytes. Please shorten your payload to ' + profileInfo.limit + ' bytes or less.';
        invalid(errorMessage);
        return;
      }

      if(toneProperties.tonelock !== undefined) {
        if(toneProperties.tonelock.length > 0) {
          var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
          if(regex.test(toneProperties.tonelock) === false) {
            errorMessage = 'Your Tone Lock value is not valid. See the ‘About Tone Options’ section for how to properly format the Tone Lock value.';
            invalid(errorMessage);
            return;
          }
        }
      }

      valid();
    }

    function createTone(toneProperties, apiToken) {
      // Authorize with API token
      const config = {
        headers: {
          Authorization: 'JWT ' + apiToken.token
        }
      };

      if(toneProperties.channel !== "1") {
        toneProperties.channel = parseInt(toneProperties.channel, 10);
      } else {
        delete toneProperties.channel;
      }

      if(toneProperties.tonelock === undefined) {
        delete toneProperties.tonelock;
      }

      if(toneProperties.tonelock !== undefined) {
        if(toneProperties.tonelock.length === 0) {
          delete toneProperties.tonelock;
        }
      }

      return HttpUtilitiesFactory._transformTonesResponseData($http.post(tonesServiceURI, toneProperties, config));
    }

    return {
      createTone: createTone,
      validateToneProperties: validateToneProperties
    }
  });
