angular.module('ControllersModule')
  .controller('DialogController', function ($scope, DialogFactory) {
  
  $scope.dialogConfirm = function() {
    DialogFactory.dialogConfirm();
  }
  
  $scope.dialogCancel = function() {
    DialogFactory.dialogCancel();
  }

  $scope.appDeleteDialogConfirm = function() {
    DialogFactory.appDeleteDialogConfirm();
  }
  
  $scope.appDeleteDialogCancel = function() {
    DialogFactory.appDeleteDialogCancel();
  }

  $scope.authDialogConfirm = function() {
    DialogFactory.authDialogConfirm();
  }
  
  $scope.authDialogCancel = function() {
    DialogFactory.authDialogCancel();
  }
});