angular.module('ControllersModule')
  .controller('AppleBundleIdController', function ($scope, $rootScope, DialogFactory, SDKTypeFactory) {

  $scope.routeAppRequired = function(event) {
    if($rootScope.user.currentapp === null) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, DialogFactory.closeDialog);
    }
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
});
