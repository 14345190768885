angular
  .module('FactoriesModule')
  .factory('HttpUtilitiesFactory', function ($q) {
      // TODO: find a better pattern to transform response.
      // $http has a transformResponse, but it's for the $http's response object,
      // not for modiying the reponse data from the server.
      function _transformResponseData(promise) {
          return promise.then(function(response) {
            if(typeof response !== 'undefined') {
              if(response.data !== '') {
                response.data.url = response.config.url;
                response.data.headers = response.headers();
              }
              return response.data;
            } else {
              return $q.reject(response);
            }
          }, function(response) {
            return $q.reject(response);
          });
      }

      function _transformTonesResponseData(promise) {
          return promise.then(function(response) {
            if(typeof response !== 'undefined') {
              return response.data;
            } else {
              return $q.reject(response);
            }
          }, function(response) {
            return $q.reject(response);
          });
      }

      return {
        '_transformResponseData': _transformResponseData,
        '_transformTonesResponseData': _transformTonesResponseData
      }
  });

