angular.module('FactoriesModule')
  .factory('LoaderDialogFactory', function ($window, $rootScope, $sce) {
  
  var completeHandler;
  
  function openLoaderDialog(scope, url, data, completeFunction) {
    $rootScope.loaderDialog.url = $sce.trustAsHtml(url).toString();
    $rootScope.loaderDialog.data = data;
    $rootScope.loaderDialog.open = true;
    completeHandler = completeFunction;

    //http://stackoverflow.com/questions/155188/trigger-a-button-click-with-javascript-on-the-enter-key-in-a-text-box
    document.onkeydown = function (evt) {
      var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
      if (keyCode === 27) {
        loaderDialogComplete();
        scope.$apply();
      } else {
        return true;
      }
    };
  }
  
  function loaderDialogComplete(data) {
    document.onkeydown = function (evt) {};
    $rootScope.loaderDialog.url = '';
    $rootScope.loaderDialog.data = '';
    $rootScope.loaderDialog.open = false;
    completeHandler(data);
  }
  
  function closeLoaderDialog() {
    document.onkeydown = function (evt) {};
    $rootScope.loaderDialog.url = '';
    $rootScope.loaderDialog.data = '';
    $rootScope.loaderDialog.open = false;
    $rootScope.loaderDialog.loaderDialogProcessing = false;
  }
  
  return {
    openLoaderDialog: openLoaderDialog,
    loaderDialogComplete: loaderDialogComplete,
    closeLoaderDialog: closeLoaderDialog
  }
  
});