angular.module('DirectivesModule')
  .directive('toggleMobileMenu', function () {

    return {
      controller: function ($rootScope, $scope) {
        $scope.toggleMobileMenu = function(close) {
          if(close) {
            $rootScope.navOpen = false;
          } else {
            $rootScope.navOpen = !$rootScope.navOpen;
          }
        }
      }
    };
  });