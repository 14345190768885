angular.module('DirectivesModule')
  .directive('previewPlayer', function($window) {

  function link(scope, element, attrs) {
    var tone = scope.tone;
    var file = scope.file;
    var audioPlayer = element.find('audio')[0];
    scope.playing = false;
    scope.progressPercent = 0;

    scope.play = function() {
      stopOtherPlayers();
      scope.playing = true;
      audioPlayer.play();
    };

    scope.stop = function() {
      scope.playing = false;
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    };

    audioPlayer.addEventListener("timeupdate", function() {
      var currentTime = audioPlayer.currentTime;
      var duration = audioPlayer.duration;
      scope.progressPercent = (currentTime/duration)*100;
      if(scope.progressPercent === 100) {
        scope.stop();
      }
      scope.$apply();
    });

    audioPlayer.addEventListener('pause', function() {
      scope.playing = false;
    })

    audioPlayer.addEventListener('ended', function() {
      scope.playing = false;
    })

    function stopOtherPlayers() {
      var audios = document.getElementsByTagName('audio');
        for(var i = 0, len = audios.length; i < len;i++){
          audios[i].pause();
          audios[i].currentTime = 0;
        }
    }
  }

  return {
    templateUrl: '/shared/directives/preview-player/preview-player-template.html',
    link: link,
    replace: true,
    scope: {
      tone: '=',
      file: '='
    }
  }
});