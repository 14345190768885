angular.module('FactoriesModule')
  .factory('HTTPFactory', function ($location, $q, $rootScope, AuthenticationFactory, DialogFactory) {

  function request(config) {
    // Add user token to Authorization header unless header is already set
    if($rootScope.user.token && !config.headers.Authorization) {
      config.headers['Authorization'] = "JWT " + $rootScope.user.token;
    }

    return config;
  }

  function responseError(response) {
    if($location.$$path !== '/login/') {
      if(response.status === 403) {
        if(response.config.url === tonesServiceURL) {
          return $q.reject(response);
        }

        if(response.data.result.message === 'Your user account has been disabled. Please contact your Account Administrator') {
          DialogFactory.openAuthDialog($rootScope, 'Error: Your user account has been disabled. Please contact your Account Administrator', false, closeDialog);
        } else {
          DialogFactory.openAuthDialog($rootScope, 'Error: You are not authorized. Logging out.', false, closeDialog);
        }
      } else if(response.status === 401) {
        DialogFactory.openAuthDialog($rootScope, 'Error: Authentication credentials not provided, or credentials are not valid. Logging out.', false, closeDialog);
      } else {
        return $q.reject(response);
      }
    } else {
      return $q.reject(response);
    }
  }

  function closeDialog() {
    DialogFactory.authCloseDialog();
    AuthenticationFactory.logout();
  }

  return {
    request: request,
    responseError: responseError
  }
});
