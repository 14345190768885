angular.module('FactoriesModule')
  .factory('URLUtilitiesFactory', function ($window) {

    var extractParameterByName = function (name, url) {

      // Prefer URLSearchParams
      if ($window.URLSearchParams) {
        if (!url) {
          return null;
        }
        var searchParams = (new URL(url)).searchParams;
        var value = searchParams.get(name);
        return (value !== null) ? value : '';
      }

      // Fall back to custom logic
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    return {
      extractParameterByName: extractParameterByName,
    }
});
