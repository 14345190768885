angular.module('ControllersModule')
  .controller('ManageUsersController', function (AuthenticationFactory, $rootScope, $timeout, $scope, AccountFactory, HttpPendingRequestsService, DialogFactory, $route) {

  $timeout(function() {
    if($rootScope.user.isadmin !== 'true') {
      DialogFactory.openDialog($scope, 'You must be an admin to access this page.', false, notAdminDialogConfirm);
    }
  });

  function notAdminDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.loadingUsers = true;
  $scope.loadingUsersError = false;
  $scope.users = [];
  $scope.search = {};

  var disableUser;
  var disableStatus;

  AccountFactory.getUsers().then(getUsersSuccess, getUsersFailure);

  function getUsersSuccess(data) {
    $scope.users = $scope.users.concat(data.result);
    if(data.headers['results-next']) {
      AccountFactory.getUsers(data.headers['results-next'], $scope.search.text).then(getUsersSuccess, getUsersFailure);
    } else {
      $scope.users.sort(function(a, b) {
        var nameA = a.username.toUpperCase();
        var nameB = b.username.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      $scope.loadingUsers = false;
    }
  }

  function getUsersFailure(data) {
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, closeDialog);
  }

  function closeDialog() {
    $route.reload();
    DialogFactory.closeDialog();
  }

  $scope.clearSearch = function() {
    $scope.search.text = '';
    $scope.searchChange();
  }

  $scope.searchChange = function() {
    HttpPendingRequestsService.cancelAll();

    $scope.loadingUsers = true;
    $scope.loadingUsersError = false;
    $scope.users = [];

    AccountFactory.getUsers({}, $scope.search.text).then(getUsersSuccess, getUsersFailure);
  }

  $scope.changeUserStatus = function(user, status) {
    disableUser = user;
    disableStatus = status;
    if(status === 'disabled') {
      DialogFactory.openDialog($scope, 'Are you sure you want to disable this user\'s account? While disabled, the user will not be able to access the portal. The user account will not be deleted and can be reactivated at any time.', true, disableUserDialogConfirm, disableUserDialogCancel);
    } else {
      disableUser.is_enabled = true;
      AccountFactory.editUserEnabled(disableUser).then(editUserEnabledSuccess, editUserEnabledFailure);
    }
  }

  function disableUserDialogConfirm() {
    if(disableStatus === 'disabled') {
      disableUser.is_enabled = false;
      AccountFactory.editUserEnabled(disableUser).then(editUserEnabledSuccess, editUserEnabledFailure);
    }
  }

  function disableUserDialogCancel() {
    DialogFactory.closeDialog();
  }

  function editUserEnabledSuccess(data) {
    $scope.editUserEnabledError = false;
    $scope.editUserEnabledProcessing = false;
    var message;
    if(data.result.is_enabled === true) {
      message = 'User Enabled.';
    } else {
      message = 'User Disabled.';
    }
    DialogFactory.openDialog($scope, message, false, dialogConfirm);
  }

  function editUserEnabledFailure() {
    $scope.editUserEnabledError = true;
    $scope.editUserEnabledProcessing = false;
    DialogFactory.openDialog($scope, 'There was an error editing the user. Please try again later.', false, dialogConfirm);
  }

  function dialogConfirm() {
    DialogFactory.closeDialog();
  }
});
