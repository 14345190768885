angular.module('ControllersModule')
  .controller('BillingController', function ($timeout, $rootScope, $scope, LoginFactory, AccountFactory, AuthenticationFactory, DialogFactory, SDKTypeFactory, $window, $location) {

  $timeout(function() {
    getInvoices()
  });
  
  $scope.loadingPage = true;
  $scope.loadingError = false;
  $scope.loadingMore = false;
  $scope.morePages = false;
  $scope.invoices = [];
  var lastInvoiceId = false;
  var initialLoad = true;

  function getInvoices() {
    $scope.loadingMore = true;

    var pagination = {
      limit: 12
    }

    if(lastInvoiceId) {
      pagination["starting_after"] = lastInvoiceId;
    }

    AccountFactory.getInvoices(pagination).then(getInvoicesSuccess, getInvoicesFailure);
  }

  function getInvoicesSuccess(data) {
    $scope.invoices = $scope.invoices.concat(parseInvoices(data.result));
    if($scope.invoices.length >= 1) {
      lastInvoiceId = $scope.invoices[$scope.invoices.length-1].id;
    }

    if(initialLoad) {
      $scope.currentInvoice = $scope.invoices.shift();
      initialLoad = false;
    }
    $scope.morePages = data.headers["has-more"] === 'true' ? true : false;
    $scope.loadingPage = false;
    $scope.loadingMore = false;
  }

  function getInvoicesFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
    $scope.loadingMore = false;
  }

  function parseInvoices(invoices) {
    var invoice_date;
    var dueDate;

    for (var i in invoices) {
      invoice_date = new Date(0);
      invoice_date.setUTCSeconds(invoices[i].invoice_date);
      dueDate = new Date(0);
      dueDate.setUTCSeconds(invoices[i].due_date);
      invoices[i].invoice_date = $window.moment(invoice_date).format('MMMM Do, YYYY');
      if(invoices[i].due_date !== null) {
        invoices[i].due_date = $window.moment(dueDate).format('MMMM Do, YYYY');
      } else {
        invoices[i].due_date = "None";
      }
      invoices[i].total = parseFloat(((invoices[i].total)/100)).toFixed(2);
      invoices[i].total = formatter.format(invoices[i].total);
    }

    return invoices;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  $scope.loadMore = function() {
    getInvoices();
  }
});
