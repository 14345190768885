angular.module('ControllersModule')
    .controller('AndroidApplicationIDController', function ($scope, $rootScope, DialogFactory) {

        $scope.routeAppRequired = function(event) {
            if($rootScope.user.currentapp === null) {
                event.preventDefault();
                DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, DialogFactory.closeDialog);
            }
        }
    });

