angular.module('ControllersModule')
  .controller('ForgotPasswordController', function ($location, $scope, ChangePasswordFactory, $timeout, $routeParams) {

  $scope.token = $routeParams.token;
  $scope.email = $routeParams.e.replace(/ /g, '+');
  $scope.passwords = [];
  $scope.forgotPasswordProcessing = false;
  $scope.forgotPasswordError = false;
  $scope.forgotPasswordSuccess = false;

  $scope.forgotPasswordSubmit = function(validPassword, validPasswordRepeat) {
    if(!$scope.forgotPasswordProcessing) {
      if(!validPassword || !validPasswordRepeat) {
        $scope.forgotPasswordError = true;
        $scope.forgotPasswordErrorMessage = 'Please complete all required fields.';
      } else {
        var errorMessage = ChangePasswordFactory.validatePasswords([$scope.passwords.newPassword, $scope.passwords.newPasswordRepeat]);

        if (errorMessage) {
          passwordsInvalid(errorMessage);
        } else {
          passwordsValid();
        }
      }
    }
  }

  function passwordsValid() {
    $scope.forgotPasswordError = false;
    $scope.forgotPasswordErrorMessage = '';
    $scope.forgotPasswordProcessing = true;
    ChangePasswordFactory.forgotPassword($scope.token, $scope.email, $scope.passwords.newPassword).then(forgotPasswordFactorySuccess, forgotPasswordFactoryFailure);
  }

  function passwordsInvalid(message) {
    $scope.forgotPasswordError = true;
    $scope.forgotPasswordErrorMessage = message;
  }

  function forgotPasswordFactorySuccess(data) {
    $scope.forgotPasswordSuccess = true;
    $timeout(goBackToLogin, 2000);
  }

  function goBackToLogin() {
    $scope.forgotPasswordProcessing = false;
    $location.path('/login/');
  }

  function forgotPasswordFactoryFailure(data) {
    $scope.forgotPasswordProcessing = false;
    $scope.forgotPasswordError = true;
    $scope.forgotPasswordErrorMessage = 'There was an error changing your password. Please try again.';
  }
});
