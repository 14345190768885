angular.module('ControllersModule')
  .controller('UserProfileController', function ($scope, $rootScope, $route, $routeParams, LoginFactory, AccountFactory, $window, DialogFactory, ChangePasswordFactory, AuthenticationFactory, SDKTypeFactory, HttpPendingRequestsService) {

  $scope.account = [];
  $scope.editing = false;
  $scope.editUserProcessing = false;
  $scope.userTypeMenuOpen = false;

  $scope.edit = [];
  $scope.edit.user = [];

  $scope.password = [];

  $scope.user = [];

  //field error boxes
  $scope.firstNameError = false;
  $scope.lastNameError = false;
  $scope.usernameError = false;
  $scope.emailError = false;

  var userId;

  $scope.loadingPage = true;
  $scope.loadingUsers = true;
  $scope.loadingUsersError = false;
  $scope.users = [];
  $scope.search = {};
  $scope.search.text = '';

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
  }

  function getAccountFailure(data) {
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, closeDialog);
  }

  function getUserDataSuccess(data) {
    userId = data.result.id;
    $scope.user = data.result;
    $scope.loadingPage = false;
    AccountFactory.getUsers().then(getUsersSuccess, getUsersFailure);
  }

  function getUserDataFailure(data) {
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, closeDialog);
  }

  function closeDialog() {
    $route.reload();
    DialogFactory.closeDialog();
  }

  function getUsersSuccess(data) {
    $scope.users = $scope.users.concat(data.result);
    if(data.headers['results-next']) {
      AccountFactory.getUsers(data.headers['results-next'], $scope.search.text).then(getUsersSuccess, getUsersFailure);
    } else {
      var i = $scope.users.length
      while (i--) {
          if ($scope.users[i].id === $rootScope.user.userid) { 
            $scope.users.splice(i, 1);
          } 
      }
      $scope.users.sort(function(a, b) {
        var nameA = a.username.toUpperCase();
        var nameB = b.username.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      $scope.users = $scope.users.filter((user) => user.is_enabled);
      $scope.loadingUsers = false;
    }
  }

  function getUsersFailure(data) {
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, closeDialog);
  }

  $scope.clearSearch = function() {
    $scope.search.text = '';
    $scope.searchChange();
  }

  $scope.searchChange = function() {
    HttpPendingRequestsService.cancelAll();

    $scope.loadingUsers = true;
    $scope.loadingUsersError = false;
    $scope.users = [];

    AccountFactory.getUsers({}, $scope.search.text).then(getUsersSuccess, getUsersFailure);
  }

  $scope.tzFilter = function(t, filter, timeZone) {
    var time = $window.moment(t);
    return time.tz(timeZone).format(filter);
  };

  $scope.toggleEdit = function() {
    $scope.editUserError = false;
    $scope.changePasswordError = false;
    $scope.edit.user.first_name = $scope.user.first_name;
    $scope.edit.user.last_name = $scope.user.last_name;
    $scope.edit.user.username = $scope.user.username;
    $scope.edit.user.email = $scope.user.email;
    $scope.edit.user.is_account_admin = $scope.user.is_account_admin;
    $scope.edit.user.user_type = $scope.user.user_type.slice();
    $scope.password = [];
    $scope.editing = !$scope.editing;
  }

  $scope.editUserSubmit = function() {
    if(!$scope.editUserProcessing) {
      $scope.firstNameError = false;
      $scope.lastNameError = false;
      $scope.usernameError = false;
      $scope.emailError = false;
      $scope.editUserError = false;
      $scope.editUserProcessing = "";
      AccountFactory.validateUser($scope.edit.user, userValid, userInvalid);
    }
  }

  function userValid() {
    $scope.editUserProcessing = true;
    AccountFactory.editUser($scope.user, $scope.edit.user).then(editUserSuccess, editUserFailure);
  }

  function userInvalid(message) {
    $scope.editUserError = true;
    $scope.editUserErrorMessage = message;
  }

  function editUserSuccess(data) {   
    $scope.user = data.result;
    AuthenticationFactory.setType($scope.user.user_type);
    $scope.editUserError = false;
    $scope.editUserProcessing = false;
    $scope.toggleEdit(); 
    DialogFactory.openDialog($scope, 'Edit Successful.', false, dialogConfirm);
  }

  function editUserFailure(data) {
    $scope.editUserProcessing = false;

    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field !== undefined) {
        showFieldError(field, message);
      } else {
        $scope.editUserError = true;
        $scope.editUserErrorMessage = 'There was an error editing the user.';
      }
    } catch (error) {
      $scope.editUserError = true;
      $scope.editUserErrorMessage = 'There was an error editing the user.';
    }
  }

  function showFieldError(field, message) {
    $scope.firstNameErrorMessage = '';
    $scope.lastNameErrorMessage = '';
    $scope.usernameErrorMessage = '';
    $scope.emailErrorMessage = '';

    switch (field) {
      case 'first_name':
        $scope.firstNameError = true;
        $scope.firstNameErrorMessage = message;
        break;
      case 'last_name':
        $scope.lastNameError = true;
        $scope.lastNameErrorMessage = message;
        break;
      case 'username':
        $scope.usernameError = true;
        $scope.usernameErrorMessage = message;
        break;
      case 'email':
        $scope.emailError = true;
        $scope.emailErrorMessage = message;
        break;
      case 'apps':
        $scope.editUserError = true;
        $scope.editUserErrorMessage = message;
        break;
      case '__all__':
        $scope.createUserError = true;
        $scope.createUserErrorMessage = message;
        break;
    }
  }

  $scope.changePasswordSubmit = function() {
    if(!$scope.editUserProcessing) {
      $scope.editUserProcessing = true;
      $scope.changePasswordError = false;

      if(typeof $scope.password.current === 'undefined') {
        passwordsInvalid('Please provide your current password.');
        return;
      }

      if(typeof $scope.password.new === 'undefined') {
        passwordsInvalid('Please provide a new password.');
        return;
      }

      if(typeof $scope.password.confirm === 'undefined') {
        passwordsInvalid('Please confirm your new password.');
        return;
      }

      if($scope.password.current === '') {
        passwordsInvalid('Please provide your current password.');
        return;
      }

      if($scope.password.new === '') {
        passwordsInvalid('Please provide a new password.');
        return;
      }

      if($scope.password.confirm === '') {
        passwordsInvalid('Please confirm your new password.');
        return;
      }

      var errorMessage = ChangePasswordFactory.validatePasswords([$scope.password.new, $scope.password.confirm]);
      if (errorMessage) {
        passwordsInvalid(errorMessage);
      } else {
        passwordsValid();
      }
    }
  }

  function passwordsValid() {
    AccountFactory.changePassword($scope.password).then(changePasswordSuccess, changePasswordFailure);
  }

  function passwordsInvalid(message) {
    $scope.changePasswordError = true;
    $scope.changePasswordErrorMessage = message;
    $scope.editUserProcessing = false;
  }

  function changePasswordSuccess(data) {
    $scope.editUserProcessing = false;
    $scope.changePasswordError = false;
    $scope.password = [];
    DialogFactory.openDialog($scope, 'Your password has been changed.', false, dialogConfirm);
  }

  function changePasswordFailure(data) {
     $scope.editUserProcessing = false;
    $scope.changePasswordError = true;
    if(data.data.result.message) {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      $scope.changePasswordErrorMessage = message;
    } else {
      $scope.changePasswordErrorMessage = 'There was an error changing your password. Please try again.';
    }
  }

  function dialogConfirm() {
    DialogFactory.closeDialog();
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;

  $scope.getToneTransactionTypeName = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeName(toneTransactionType);
  }

  $scope.getToneTransactionTypeDisplay = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeDisplay(toneTransactionType);
  }

  $scope.getBillingTypeName = function(billingType) {
    return AccountFactory.getBillingTypeName(billingType);
  }

  $scope.getBillingTypeDisplay = function(billingType) {
    return AccountFactory.getBillingTypeDisplay(billingType);
  }
});
