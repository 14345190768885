angular.module('FactoriesModule')
  .factory('AnalyticsFactory', function ($http, $rootScope, $window, HttpUtilitiesFactory) {

  function getAnalyticsDashboardUrl() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+$rootScope.user.currentapp.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  return {
    getAnalyticsDashboardUrl: getAnalyticsDashboardUrl
  }
});