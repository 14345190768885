angular.module('DirectivesModule')
  .directive('bootstrapDateTimePicker', function($window) {

  // https://eonasdan.github.io/bootstrap-datetimepicker/
  
  function link(scope, element, attrs) {
    var dateTimePicker = element.find('input');
    var initDate;
    var initFormat;

    scope.control.init = function(init, tz, format) {
      tz = 'UTC';
      if(init !== undefined) {
        if(tz !== '') {
          initDate = $window.moment.tz(init, tz).format('YYYY-MM-DD[T]HH:mm');
        } else {
          initDate = $window.moment(init).format('YYYY-MM-DD[T]HH:mm');
        }
      }

      if(format !== undefined) {
        initFormat = format;
      } else {
        initFormat = false;
      }

      dateTimePicker.datetimepicker({
        defaultDate: initDate,
        format: initFormat
      });

      dateTimePicker.on('dp.change', function () {
        if(dateTimePicker.data("DateTimePicker").date() !== null) {
          scope.model = dateTimePicker.data("DateTimePicker").date().format('YYYY-MM-DD[T]HH:mm');
        } else {
          scope.model = undefined;
        }
        scope.$apply();
      });
    }
  }

  return {
    templateUrl: '/shared/directives/bootstrap-date-time-picker/bootstrap-date-time-picker-template.html',
    link: link,
    scope: {
      control: '=',
      model: '='
    }
  }
});