angular.module('ControllersModule')
  .controller('ManageUserController', function (AuthenticationFactory, $timeout, $route, $scope, $rootScope, $routeParams, LoginFactory, AccountFactory, $window, DialogFactory, ChangePasswordFactory, SDKTypeFactory) {

  $timeout(function() {
    if($rootScope.user.isadmin !== 'true') {
      DialogFactory.openDialog($scope, 'You must be an admin to access this page.', false, notAdminDialogConfirm);
    }
  });

  function notAdminDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  var userId = $routeParams.id;

  $scope.loadingUserInfo = true;
  $scope.editing = false;
  $scope.editUserProcessing = false;

  $scope.edit = [];
  $scope.edit.user = [];

  $scope.password = [];

  $scope.user = [];

  $scope.pageError = false;

  //field error boxes
  $scope.firstNameError = false;
  $scope.lastNameError = false;
  $scope.usernameError = false;
  $scope.emailError = false;

  AccountFactory.getUserById(userId).then(getUserDataSuccess, getUserDataFailure);

  function closeDialog() {
    $route.reload();
    DialogFactory.closeDialog();
  }

  function getUserDataSuccess(data) {
    userId = data.result.id;
    $scope.user = data.result;
    $scope.loadingUserInfo = false;
  }

  function getUserDataFailure(data) {
    $scope.pageError = true;
    $scope.loadingUserInfo = false;
  }

  $scope.administratorCheckboxToggle = function() {
    $scope.edit.user.is_account_admin = !$scope.edit.user.is_account_admin;
    $scope.edit.user.user_type = ["developer"]; //add billing here when releasing billing to everyone
  }

  $scope.developerCheckboxToggle = function() {
    if(!$scope.edit.user.is_account_admin) {
      var index = $scope.edit.user.user_type.indexOf("developer");
      if (index !== -1) {
        $scope.edit.user.user_type.splice(index, 1);
      } else {
        $scope.edit.user.user_type.push("developer");
      }
    }
  }

  $scope.billingCheckboxToggle = function() {
    if(!$scope.edit.user.is_account_admin) {
      var index = $scope.edit.user.user_type.indexOf("billing");
      if (index !== -1) {
        $scope.edit.user.user_type.splice(index, 1);
      } else {
        $scope.edit.user.user_type.push("billing");
      }
    }
  }

  $scope.tzFilter = function(t, filter, timeZone) {
    var time = $window.moment(t);
    return time.tz(timeZone).format(filter);
  };

  $scope.toggleEdit = function() {
    $scope.editUserError = false;
    $scope.changePasswordError = false;
    $scope.edit.user.first_name = $scope.user.first_name;
    $scope.edit.user.last_name = $scope.user.last_name;
    $scope.edit.user.username = $scope.user.username;
    $scope.edit.user.email = $scope.user.email;
    $scope.edit.user.is_account_admin = $scope.user.is_account_admin;
    $scope.edit.user.user_type = $scope.user.user_type.slice();
    $scope.password = [];
    $scope.editing = !$scope.editing;
  }

  $scope.editUserSubmit = function() {
    if(!$scope.editUserProcessing) {
      $scope.firstNameError = false;
      $scope.lastNameError = false;
      $scope.usernameError = false;
      $scope.emailError = false;
      $scope.editUserError = false;
      $scope.editUserProcessing = "";
      AccountFactory.validateUser($scope.edit.user, userValid, userInvalid);
    }
  }

  function userValid() {
    $scope.editUserProcessing = true;
    AccountFactory.editUser($scope.user, $scope.edit.user).then(editUserSuccess, editUserFailure);
  }

  function userInvalid(message) {
    $scope.editUserError = true;
    $scope.editUserErrorMessage = message;
  }

  function editUserSuccess(data) {    
    DialogFactory.openDialog($scope, 'Edit Successful.', false, dialogConfirm);
  }

  function editUserFailure(data) {
    $scope.editUserProcessing = false;

    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field !== undefined) {
        showFieldError(field, message);
      } else {
        $scope.editUserError = true;
        $scope.editUserErrorMessage = 'There was an error editing the user.';
      }
    } catch (error) {
      $scope.editUserError = true;
      $scope.editUserErrorMessage = 'There was an error editing the user.';
    }
  }

  function showFieldError(field, message) {
    $scope.firstNameErrorMessage = '';
    $scope.lastNameErrorMessage = '';
    $scope.usernameErrorMessage = '';
    $scope.emailErrorMessage = '';

    switch (field) {
      case 'first_name':
        $scope.firstNameError = true;
        $scope.firstNameErrorMessage = message;
        break;
      case 'last_name':
        $scope.lastNameError = true;
        $scope.lastNameErrorMessage = message;
        break;
      case 'username':
        $scope.usernameError = true;
        $scope.usernameErrorMessage = message;
        break;
      case 'email':
        $scope.emailError = true;
        $scope.emailErrorMessage = message;
        break;
      case '__all__':
        $scope.editUserError = true;
        $scope.editUserErrorMessage = message;
        break;
    }
  }

  $scope.changeUserPasswordSubmit = function() {
    if(!$scope.editUserProcessing) {
      $scope.editUserProcessing = true;
      $scope.changePasswordError = false;

      if(typeof $scope.password.new === 'undefined') {
        userPasswordsInvalid('Please provide a new password.');
        return;
      }

      if($scope.password.new === '') {
        userPasswordsInvalid('Please provide a new password.');
        return;
      }

      if(typeof $scope.password.confirm === 'undefined') {
        userPasswordsInvalid('Please confirm your new password.');
        return;
      }

      if($scope.password.confirm === '') {
        userPasswordsInvalid('Please confirm your new password.');
        return;
      }

      var errorMessage = ChangePasswordFactory.validatePasswords([$scope.password.new, $scope.password.confirm]);
      if (errorMessage) {
        userPasswordsInvalid(errorMessage);
      } else {
        userPasswordsValid();
      }
    }
  }

  function userPasswordsValid() {
    AccountFactory.editUserPassword({id: $scope.user.id, password: $scope.password.new}).then(changeUserPasswordSuccess, changeUserPasswordFailure);
  }

  function userPasswordsInvalid(message) {
    $scope.changePasswordError = true;
    $scope.changePasswordErrorMessage = message;
    $scope.editUserProcessing = false;
  }

  function changeUserPasswordSuccess(data) {
    $scope.editUserProcessing = false;
    $scope.toggleEdit();
    DialogFactory.openDialog($scope, $scope.user.username+'\'s password has been changed. Please communicate this change to '+$scope.user.username+'.', false, dialogConfirm);
  }

  function changeUserPasswordFailure(data) {
     $scope.editUserProcessing = false;
    $scope.changePasswordError = true;
    if(data.data.result.message) {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      $scope.changePasswordErrorMessage = message;
    } else {
      $scope.changePasswordErrorMessage = 'There was an error changing your password. Please try again.';
    }
  }

  $scope.toggleUserStatus = function() {
    if($rootScope.user.expiredtrial === 'false') {
      if($scope.user.is_enabled) {
        AccountFactory.editUserEnabled({id: $scope.user.id, is_enabled: false}).then(toggleUserStatusSuccess, toggleUserStatusFailure);
      } else {
        AccountFactory.editUserEnabled({id: $scope.user.id, is_enabled: true}).then(toggleUserStatusSuccess, toggleUserStatusFailure);
      }
    }
  }

  function toggleUserStatusSuccess(data) {
    var message;
    if($scope.user.is_enabled === true) {
      message = 'User Disabled.';
    } else {
      message = 'User Enabled.';
    }
    DialogFactory.openDialog($scope, message, false, dialogConfirm);
  }

  function toggleUserStatusFailure() {
    $scope.editUserEnabledError = true;
    $scope.editUserEnabledProcessing = false;
    if($scope.user.is_enabled === true) {
      message = 'There was an error disabling the user. Please try again later.';
    } else {
      message = 'There was an error enabling the user. Please try again later.';
    }
    DialogFactory.openDialog($scope, message, false, dialogConfirm);
  }

  function dialogConfirm() {
    DialogFactory.closeDialog();
    $window.location.reload();
  }
});
