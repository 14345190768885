angular.module('ControllersModule')
  .controller('AppsController', function ($timeout, $rootScope, $scope, LoginFactory, AccountFactory, AuthenticationFactory, DialogFactory, SDKTypeFactory, $window, $location) {

  $timeout(function() {
    AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);
  });

  // https://tc39.github.io/ecma262/#sec-array.prototype.includes
  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function(searchElement, fromIndex) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
        var o = Object(this);
        var len = o.length >>> 0;
        if (len === 0) {
          return false;
        }
        var n = fromIndex | 0;
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }
        while (k < len) {
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          k++;
        }
        return false;
      }
    });
  }
  
  $scope.loadingPage = true;
  $scope.loadingError = false;
  $scope.createNewAppProcessing = false;
  $scope.createNewAppError = false;
  $scope.createNewAppErrorMessage = '';
  $scope.account = [];
  $scope.newApp = [];
  $scope.newApp.type = "online";
  $scope.newApp.sdk_type = '';
  $scope.newApp.profiles = [];
  $scope.newApp.sda_expiration_delta_seconds = 3600;
  $scope.newApp.sda_integration_type = "default";
  $scope.newApp.sda_transport_type = "default";
  $scope.newApp.is_test_app = false;
  $scope.newApp.tone_transaction_type = "";
  $scope.newApp.billing_type = "";
  var newAppHolder;

  function getAccountSuccess(data) {
    $scope.account = data.result[0];

    // Set default SDK type
    // Leave empty if account has only legacy enabled
    if ($scope.account.products.includes('radius3')) {
      $scope.newApp.sdk_type = 'radius3';
    } else if ($scope.account.products.includes('radius')) {
      $scope.newApp.sdk_type = 'radius';
    } else if ($scope.account.products.includes('point')) {
      $scope.newApp.sdk_type = 'point';
    } else if ($scope.account.products.includes('sda')) {
      $scope.newApp.sdk_type = 'sda';
    }

    $scope.billingTypes = $scope.account.billing_type.map(billing_type => AccountFactory.getBillingTypeName(billing_type));
    $scope.toneTransactionTypes = $scope.account.tone_transaction_type.map(tone_transaction_type => AccountFactory.getToneTransactionTypeName(tone_transaction_type));

    if ($scope.billingTypes.length > 0)  {
      $scope.newApp.billing_type = $scope.billingTypes[0];
    }

    if ($scope.toneTransactionTypes.length > 0)  {
      $scope.newApp.tone_transaction_type = $scope.toneTransactionTypes[0];
    }

    $scope.loadingPage = false;
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }

  $scope.createNewAppSubmit = function(validAppName) {
    if(!$scope.createNewAppProcessing) {

      if(!validAppName) {
        $scope.createNewAppError = true;
        $scope.createNewAppErrorMessage = 'Please complete all required fields.';
        return;
      }

      if($scope.newApp.sdk_type === "sda") {
        if($scope.newApp.sda_expiration_delta_seconds === '' ||
          $scope.newApp.sda_expiration_delta_seconds > 172800 ||
          $scope.newApp.sda_expiration_delta_seconds < 1
        ) {
          $scope.createNewAppError = true;
          $scope.createNewAppErrorMessage = 'SDA Expiration Delta Seconds must be an integer between 1 and 172800.';
          return;
        } else if(!Number.isInteger(parseFloat($scope.newApp.sda_expiration_delta_seconds)) || checkOnlyNumbers() == false) {
          $scope.createNewAppError = true;
          $scope.createNewAppErrorMessage = 'SDA Expiration Delta Seconds must be an integer.';
          return;
        }
      }

      $scope.createNewAppError = false;
      $scope.createNewAppProcessing = true;
      AccountFactory.createNewApp($scope.newApp).then(createNewAppSuccess, createNewAppFailure);
    }
  }

  function checkOnlyNumbers() {
    var isnum = /^\d+$/.test($scope.newApp.sda_expiration_delta_seconds);

    if(isnum) {
      return true;
    } else {
      return false;
    }
  }
  
  function createNewAppSuccess(data) {
    newAppHolder = data.result;
    LoginFactory.getAppKey(data.result.id).then(getAppKeySuccess, createNewAppFailure);
  }

  function getAppKeySuccess(data) {
    AuthenticationFactory.setCurrentApp(JSON.stringify(newAppHolder));
    $rootScope.apps.push(newAppHolder);
    $rootScope.apps.sort(function(a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    DialogFactory.openDialog($scope, 'Your new app has been created.', false, closeDialog);
    $scope.createNewAppProcessing = false;
  }
  
  function createNewAppFailure(data) {
    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field === '__all__') {
        DialogFactory.openDialog($scope, 'Error: '+message, false, closeDialogFailure);
      } else {
        DialogFactory.openDialog($scope, 'There was an error creating your new app. Please try again later.', false, closeDialogFailure);
      }
    } catch (error) {
      DialogFactory.openDialog($scope, 'There was an error creating your new app. Please try again later.', false, closeDialogFailure);
    }
  }

  function closeDialog() {
    $scope.createNewAppProcessing = false;
    DialogFactory.closeDialog();
    $location.path('/apps/'+newAppHolder.id+'/sdk-tokens/');
  }

  function closeDialogFailure() {
    $scope.createNewAppProcessing = false;
    DialogFactory.closeDialog();
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;

  $scope.showIntegrationType = false;

  $scope.toggleIntegrationType = function(close) {
    if(close) {
      $scope.showIntegrationType = false;
      return;
    }
    $scope.showIntegrationType = !$scope.showIntegrationType;
  }

  $scope.setIntegrationType = function(type) {
    $scope.showIntegrationType = false;
    $scope.newApp.sda_integration_type = type;
  }

  $scope.showTransportType = false;

  $scope.toggleTransportType = function(close) {
    if(close) {
      $scope.showTransportType = false;
      return;
    }
    $scope.showTransportType = !$scope.showTransportType;
  }

  $scope.setTransportType = function(type) {
    $scope.showTransportType = false;
    $scope.newApp.sda_transport_type = type;
  }

  $scope.testAppCheckboxToggle = function () {
    $scope.newApp.is_test_app = !$scope.newApp.is_test_app;
  };

  $scope.getBillingTypeDisplay = function(billingType) {
    return AccountFactory.getBillingTypeDisplay(billingType);
  }

  $scope.getToneTransactionTypeDisplay = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeDisplay(toneTransactionType);
  }
});
