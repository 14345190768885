angular.module('ControllersModule')
  .controller('AppSettingsLinkController', function ($scope, $rootScope, DialogFactory, SDKTypeFactory) {

  $scope.routeAppRequired = function(event) {
    if($rootScope.user.currentapp === null) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, DialogFactory.closeDialog);
    }
  }

  $scope.routeAdminRequired = function(event) {
    if($rootScope.user.isadmin !== 'true' && !$rootScope.user.user_type.includes('developer')) {
      event.preventDefault();
      DialogFactory.openDialog($scope, 'You must be an admin or a developer to access this page.', false, DialogFactory.closeDialog);
    }
  }
  
  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
});
