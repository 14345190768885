angular.module('FactoriesModule')
  .factory('APITokensFactory', function ($http, $rootScope, HttpUtilitiesFactory, URLUtilitiesFactory) {

  function getAPITokens(id, pagination) {
    var params = {};

    if (pagination && typeof(pagination) === 'string') {
      var limit = URLUtilitiesFactory.extractParameterByName('limit', pagination);
      var offset = URLUtilitiesFactory.extractParameterByName('offset', pagination);

      if (!isNaN(limit)) {
        params.limit = limit;
      }

      if (!isNaN(offset)) {
        params.offset = offset;
      }
    } else if (pagination) {
      params.limit = pagination.limit;
      params.offset = pagination.offset;
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/api-tokens').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {'params': params}));
  };

  function createAPIToken(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/api-tokens').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {}));
  };

  function deleteAPIToken(id, token_id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/api-tokens/'+token_id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.delete(url));
  };

  return {
    getAPITokens: getAPITokens,
    createAPIToken: createAPIToken,
    deleteAPIToken: deleteAPIToken,
  }
});
