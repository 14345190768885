angular.module('ControllersModule')
  .controller('AddUserController', function (AuthenticationFactory, $timeout, $scope, $rootScope, $routeParams, LoginFactory, AccountFactory, $window, DialogFactory, $route, ChangePasswordFactory, $location) {

  $timeout(function() {
    if($rootScope.user.isadmin !== 'true') {
      DialogFactory.openDialog($scope, 'You must be an admin to access this page.', false, notAdminDialogConfirm);
    }
  });

  function notAdminDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.createUserProcessing = false;

  $scope.create = [];
  $scope.create.user = [];
  $scope.create.user.user_type = [];
  $scope.password = [];

  //field error boxes
  $scope.firstNameError = false;
  $scope.lastNameError = false;
  $scope.usernameError = false;
  $scope.emailError = false;
  $scope.passwordError = false;

  $scope.createUserSubmit = function() {
    if(!$scope.createUserProcessing) {
      $scope.firstNameError = false;
      $scope.lastNameError = false;
      $scope.usernameError = false;
      $scope.emailError = false;
      $scope.passwordError = false;

      $scope.createUserError = false;
      $scope.createUserErrorMessage = "";

      AccountFactory.validateUser($scope.create.user, userValid, userInvalid);
    }
  }

  function userValid() {
    if(typeof $scope.password.new === 'undefined') {
      passwordInvalid('Please provide a password.');
      return;
    }

    if($scope.password.new === '') {
      passwordInvalid('Please provide a password.');
      return;
    }

    if(typeof $scope.password.confirm === 'undefined') {
      passwordInvalid('Please confirm your password.');
      return;
    }

    if($scope.password.confirm === '') {
      passwordInvalid('Please confirm your password.');
      return;
    }

    var errorMessage = ChangePasswordFactory.validatePasswords([$scope.password.new, $scope.password.confirm]);
    if (errorMessage) {
      passwordInvalid(errorMessage);
    } else {
      passwordValid();
    }
  }

  function userInvalid(message) {
    $scope.createUserError = true;
    $scope.createUserErrorMessage = message;
  }

  function passwordValid() {
    $scope.createUserProcessing = true;
    AccountFactory.createUser($scope.create.user, $scope.password.new).then(createUserSuccess, createUserFailure);
  }

  function passwordInvalid(message) {
    $scope.createUserError = true;
    $scope.createUserErrorMessage = message;
  }

  function createUserSuccess(data) {
    DialogFactory.openDialog($scope, 'User Created.', false, userCreatedDialogConfirm);
  }

  function userCreatedDialogConfirm() {
    $location.path('/account/users/user-profile/');
    DialogFactory.closeDialog();
  }

  function createUserFailure(data) {
    $scope.createUserProcessing = false;

    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field !== undefined) {
        showFieldError(field, message);
      } else {
        $scope.createUserError = true;
        $scope.createUserErrorMessage = 'There was an error creating the user.';
      }
    } catch (error) {
      $scope.createUserError = true;
      $scope.createUserErrorMessage = 'There was an error creating the user.';
    }
  }

  function showFieldError(field, message) {
    $scope.firstNameErrorMessage = '';
    $scope.lastNameErrorMessage = '';
    $scope.usernameErrorMessage = '';
    $scope.emailErrorMessage = '';
    $scope.passwordErrorMessage = '';

    switch (field) {
      case 'first_name':
        $scope.firstNameError = true;
        $scope.firstNameErrorMessage = message;
        break;
      case 'last_name':
        $scope.lastNameError = true;
        $scope.lastNameErrorMessage = message;
        break;
      case 'username':
        $scope.usernameError = true;
        $scope.usernameErrorMessage = message;
        break;
      case 'email':
        $scope.emailError = true;
        $scope.emailErrorMessage = message;
        break;
      case 'password':
        $scope.passwordError = true;
        $scope.passwordErrorMessage = message;
        break;
      case '__all__':
        $scope.createUserError = true;
        $scope.createUserErrorMessage = message;
        break;
    }
  }

  $scope.administratorCheckboxToggle = function() {
    $scope.create.user.is_account_admin = !$scope.create.user.is_account_admin;
    $scope.create.user.user_type = ["developer"]; //add billing here when releasing billing to everyone
  }

  $scope.developerCheckboxToggle = function() {
    if(!$scope.create.user.is_account_admin) {
      var index = $scope.create.user.user_type.indexOf("developer");
      if (index !== -1) {
        $scope.create.user.user_type.splice(index, 1);
      } else {
        $scope.create.user.user_type.push("developer");
      }
    }
  }

  $scope.billingCheckboxToggle = function() {
    if(!$scope.create.user.is_account_admin) {
      var index = $scope.create.user.user_type.indexOf("billing");
      if (index !== -1) {
        $scope.create.user.user_type.splice(index, 1);
      } else {
        $scope.create.user.user_type.push("billing");
      }
    }
  }

  function dialogConfirm() {
    DialogFactory.closeDialog();
  }
});
