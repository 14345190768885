angular.module('ControllersModule')
.controller('SDKTokensController', function (AccountFactory, $scope, $rootScope, $timeout, AuthenticationFactory, SDKTokensFactory, SDKTypeFactory, LastActivityFactory, DialogFactory) {

  $scope.account = [];
  $scope.sdkTokens = [];

  $timeout(function() {
    if($rootScope.user.currentapp === null) {
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
    } else if ($rootScope.user.currentapp.sdk_type === 'legacy') {
      DialogFactory.openDialog($scope, 'The current app does not support the content on this page.', false, noAppDialogConfirm);
    } else {
      if ($rootScope.user.trial === 'true') {
        $scope.accountState = 'trial';
      } else if ($rootScope.user.expiredtrial === 'true') {
        $scope.accountState = 'expired-trial';
      } else {
        $scope.accountState = 'live';
      }

      AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);
    }
  });

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    loadSDKTokens();
  }

  function getAccountFailure(data) {
    $scope.loadingDeveloperInfo = false;
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, getSDKInfoFailureDialogConfirm);
  }

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  // Load SDKTokens
  function loadSDKTokens() {
    SDKTokensFactory.getSDKTokens($rootScope.user.currentapp.id).then(loadSDKTokensSuccess, loadSDKTokensFailure);
  }

  function loadSDKTokensSuccess(data) {
    $scope.sdkTokens = $scope.sdkTokens.concat(data.result);

    var pagination = data.headers['results-next'];

    if (pagination) {
      SDKTokensFactory.getSDKTokens($rootScope.user.currentapp.id, pagination).then(loadSDKTokensSuccess, loadSDKTokensFailure);
    } else {
      $scope.loadingSDKTokens = false;
    }
  }

  function loadSDKTokensFailure() {
    DialogFactory.openDialog($scope, 'There was an error loading your SDK Tokens. Please try again later.', false, closeDialogSDKTokensFailure);
  }

  // Delete SDKTokens
  $scope.deleteSDKToken = function(event, token) {
    event.stopPropagation();
    $scope.deleteTokenId = token.id;
    DialogFactory.openDialog($scope, 'Are you sure you want to delete this token? Anything using this token will no longer activate the SDK.', true, $scope.deleteSDKTokenDialogConfirm, $scope.deleteSDKTokenDialogCancel);
  }

  $scope.deleteSDKTokenDialogConfirm = function() {
    SDKTokensFactory.deleteSDKToken($rootScope.user.currentapp.id, $scope.deleteTokenId).then(deleteSDKTokensSuccess, deleteSDKTokensFailure);
  }

  $scope.deleteSDKTokenDialogCancel = function() {
    DialogFactory.closeDialog();
  }

  function deleteSDKTokensSuccess() {
    $scope.sdkTokens = $scope.sdkTokens.filter(function(token) {
      return token.id != $scope.deleteTokenId
    });
    DialogFactory.closeDialog();
  }

  function deleteSDKTokensFailure() {
    DialogFactory.closeDialog();
    DialogFactory.openDialog($scope, 'There was an error deleting your SDK Token. Please try again later.', false, closeDialogSDKTokensFailure);
  }

  function closeDialogSDKTokensFailure() {
    DialogFactory.closeDialog();
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
  $scope.lastActivityDisplay = LastActivityFactory.lastActivityDisplay;

  var clipboardJWT = new Clipboard('#sdk-token-copy-button');
  clipboardJWT.on('success', function (event) {
    $(document).find('.copy-message').empty();
    $(event.trigger).parent().parent().find('p.copy-message')[0].innerHTML = 'Your token has been successfully copied.';
    event.clearSelection();
  });

  $scope.getBillingTypeDisplay = function(billingType) {
    return AccountFactory.getBillingTypeDisplay(billingType);
  }

  $scope.getToneTransactionTypeDisplay = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeDisplay(toneTransactionType);
  }
});
