angular.module('ControllersModule')
  .controller('ToneSpeedCalculatorController', function ($scope, AccountFactory, ToneSpeedFactory) {

  Number.isInteger = Number.isInteger || function(value) {
    return typeof value === 'number' && 
      isFinite(value) && 
      Math.floor(value) === value;
  };

  const profileDisplayName = {
    'standard2': 'Standard 2.0',
    'standard2_wideband': 'Standard 2.0 Wideband',
    'compression': 'Compression',
    'pkab2': 'Precision KAB 2.0',
    'pkab2_wideband': 'Precision KAB 2.0 Wideband',
    'zone66': 'Zone 66',
    'point1000': 'Point 1000',
    'point2000': 'Point 2000',
  };

  $scope.loadingPage = true;
  $scope.loadingError = false;
  $scope.account = [];

  $scope.maxPayloadBytes = 0;
  $scope.tone = [];
  $scope.tone.type = 'data';
  $scope.tone.payloadBytes = 6;
  $scope.adjustedPayloadBytes = 6;
  $scope.tone.tonelock = false;

  $scope.overheadSeconds = 0;
  $scope.payloadSeconds = 0;
  $scope.totalSeconds = 0;

  $scope.profileOpen = false;

  $scope.$watch('tone.payloadBytes', function(newVal, oldVal) {
    // Skip during initialization
    if (newVal === oldVal) {
      return;
    }

    $scope.recalculateToneSpeed()
  })

  $scope.clickOutsideProfile = function() {
    $scope.profileOpen = false;
  }

  $scope.toggleProfileDropdown = function() {
    $scope.profileOpen = !$scope.profileOpen;
  }

  $scope.selectProfile = function(profile) {
    $scope.tone.profile = profile;
    $scope.recalculateToneSpeed();
    $scope.toggleProfileDropdown();
  }

  $scope.recalculateToneSpeed = function() {
    $scope.maxPayloadBytes = 255;
    if ($scope.tone.profile === 'pkab2' || $scope.tone.profile === 'pkab2_wideband' || $scope.tone.profile === 'point1000' || $scope.tone.profile === 'point2000') {
      $scope.maxPayloadBytes = 3000;
    }

    $scope.adjustedPayloadBytes = $scope.tone.payloadBytes;

    if($scope.adjustedPayloadBytes === '') {
      $scope.adjustedPayloadBytes = 0;
      $scope.lengthError = false;
    } else if($scope.adjustedPayloadBytes > $scope.maxPayloadBytes) {
      $scope.adjustedPayloadBytes = 0;
      $scope.lengthError = true;
      $scope.lengthErrorMessage = $scope.maxPayloadBytes.toString() + ' Bytes is the maximum length size for a payload.';
    } else if($scope.adjustedPayloadBytes < 0) {
      $scope.adjustedPayloadBytes = 0;
      $scope.lengthError = true;
      $scope.lengthErrorMessage = 'Payload length must be a positive integer.';
    } else if(!Number.isInteger(parseFloat($scope.adjustedPayloadBytes))) {
      $scope.adjustedPayloadBytes = 0;
      $scope.lengthError = true;
      $scope.lengthErrorMessage = 'Payload length must be an integer.';
    } else if(checkOnlyNumbers() == false) {
      $scope.adjustedPayloadBytes = 0;
      $scope.lengthError = true;
      $scope.lengthErrorMessage = 'Payload length must be an integer.';
    } else {
      $scope.lengthError = false;
    } 

    $scope.tone.displayProfile = profileDisplayName[$scope.tone.profile];

    switch ($scope.tone.profile) {
      case 'standard2':
      case 'compression':
        $scope.bitsPerSecondDisplay = 33.3;
        break;
      case 'standard2_wideband':
        $scope.bitsPerSecondDisplay = 66.6;
        break;
      case 'pkab2':
        $scope.bitsPerSecondDisplay = 1000;
        break;
      case 'pkab2_wideband':
        $scope.bitsPerSecondDisplay = 2000;
        break;
      case 'zone66':
        $scope.bitsPerSecondDisplay = 66.6;
        break;
      case 'point1000':
        $scope.bitsPerSecondDisplay = 1000;
        break;
      case 'point2000':
        $scope.bitsPerSecondDisplay = 2000;
        break;
    }

    console.log($scope.tone);
    const includeTonePrivacyId = ($scope.account.tone_privacy_id !== null);
    const results = ToneSpeedFactory.calculateToneSpeed($scope.adjustedPayloadBytes, $scope.tone.profile, includeTonePrivacyId, "wav", false, $scope.tone.tonelock);

    console.log(results);
    $scope.overheadSeconds = results.overheadSeconds.toFixed(3);
    $scope.payloadSeconds = results.payloadSeconds.toFixed(3);
    $scope.totalSeconds = results.totalSeconds.toFixed(3);

    if($scope.adjustedPayloadBytes == 0) {
      $scope.payloadSeconds = 0;
      $scope.overheadSeconds = 0;
      $scope.totalSeconds = 0;
    }
  }

  function checkOnlyNumbers() {
    var isnum = /^\d+$/.test($scope.tone.payloadBytes);

    if(isnum) {
      return true;
    } else {
      return false;
    }
  }

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    $scope.loadingPage = false;

    if($scope.account.products.includes('radius3')) {
      $scope.tone.profile = 'zone66';
      $scope.tone.displayProfile = 'Zone 66';
    } else {
      $scope.tone.profile = 'standard2';
      $scope.tone.displayProfile = 'Standard 2';
    }

    $scope.recalculateToneSpeed();
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  $scope.tonelockCheckboxToggle = function () {
    $scope.tone.tonelock = !$scope.tone.tonelock;
    $scope.recalculateToneSpeed();
  };
});
