angular.module('FactoriesModule')
  .factory('AccountFactory', function ($http, $rootScope, HttpUtilitiesFactory, URLUtilitiesFactory) {

  function validateUser(user, valid, invalid) {
    if(typeof user.is_account_admin === 'undefined') {
      user.is_account_admin = false;
    }

    if(typeof user.first_name === 'undefined') {
      invalid('Please provide a first name.');
      return;
    }

    if(user.first_name === null) {
      invalid('Please provide a first name.');
      return;
    }

    if(typeof user.last_name === 'undefined') {
      invalid('Please provide a last name.');
      return;
    }

    if(user.last_name === null) {
      invalid('Please provide a last name.');
      return;
    }

    if(typeof user.username === 'undefined') {
      invalid('Please provide a username.');
      return;
    }

    if(user.is_account_admin === true) {
      if(typeof user.email === 'undefined') {
        invalid('Email is required for account administrators.');
        return;
      }
      if(user.email === '') {
        invalid('Email is required for account administrators.');
        return;
      }
    } else {
      if(user.user_type.length === 0) {
        invalid('At least one role is required for non administrators.');
        return;
      }
    }

    valid();
  }

  function getUsers(pagination, search) {
    return _getUsersWithParams({'pagination': pagination, 'search': search});
  };

  function _getUsersWithParams(query) {
    if ('search' in query) {
      var search = query['search'];
      delete query['search'];

      if (search && search.length > 0) {
        query["search"] = search;
      }
    }

    if ('pagination' in query) {
      if(query['pagination'] && Object.keys(query['pagination']).length !== 0) {
          query.limit = URLUtilitiesFactory.extractParameterByName('limit', query['pagination']);
          query.offset = URLUtilitiesFactory.extractParameterByName('offset', query['pagination']);
      } else {
        query.limit = 0;
        query.offset = 0;
      }
      
      delete query['pagination'];
    }

    //setting default sort by and direction
    query['sort'] = 'username';
    query['direction'] = 'ascending';

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {'params':query}));
  };

  function getUserData() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/me').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getUserById(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getApp(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getAppKeys(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+id+'/keys').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getAppV2(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getUserApps() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getAllApps() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('accounts').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getAccount() {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('accounts').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {}));
  };

  function getInvoices(pagination) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('invoices').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, { params: pagination }));
  };

  function createNewApp(app) {
    var params = {
      name: app.name,
      type: app.type,
      sdk_type: app.sdk_type,
      ...(app.sdk_type === "sda" && {
        sda_expiration_delta_seconds: parseInt(app.sda_expiration_delta_seconds, 10),
        sda_integration_type: app.sda_integration_type,
        sda_transport_type: app.sda_transport_type
      }),
      ...(app.sdk_type === "radius3" && {
        is_test_app: app.is_test_app,
        tone_transaction_type: app.tone_transaction_type,
        billing_type: app.billing_type
      }),
    }

    if(params.tone_transaction_type === "") {
      delete params.tone_transaction_type;
    }

    if(params.billing_type === "") {
      delete params.billing_type;
    }

    if (app.profiles.length > 0) {
      params['profiles'] = app.profiles;
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, params));
  };

  function editApp(app) {
    var params = {
      ...(app.sdk_type === "legacy" && {
        profiles: app.profiles
      }),
      ...(app.sdk_type === "sda" && {
        sda_expiration_delta_seconds: parseInt(app.extra.sda_expiration_delta_seconds, 10),
        sda_integration_type: app.extra.sda_integration_type,
        sda_transport_type: app.extra.sda_transport_type
      }),
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+app.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.put(url, params));
  };

  function deleteApp(app) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+app.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.delete(url));
  };

  function editUser(user, userEdit) {
    if(userEdit.email === undefined) {
      userEdit.email = '';
    }
    var params = {
      first_name: userEdit.first_name,
      last_name: userEdit.last_name,
      email: userEdit.email,
      username: userEdit.username,
      is_account_admin: userEdit.is_account_admin,
      user_type: userEdit.user_type
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+user.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.put(url, params));
  };

  function changePassword(password) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('auth/password/change').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      current_password: password.current,
      new_password: password.new
    }));
  };

  function editUserEnabled(user) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+user.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.put(url, {
      is_enabled: user.is_enabled
    }));
  };

  function editUserPassword(user) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+user.id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.put(url, {
      password: user.password
    }));
  };

  function createUser(user, password) {
    var params = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.username,
      user_type: user.user_type,
      is_account_admin: user.is_account_admin,
      password: password
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, params));
  }

  function getBillingTypeName(billingType) {
    var billingTypes = [
      "transaction-based",
      "flat-fee",
      "freemium"
    ]

    return billingTypes[billingType-1]
  }

  function getToneTransactionTypeName(toneTransactionType) {
    var toneTransactionTypes = [
      "payment",
      "identification",
      "confirmation",
      "device_pairing",
      "proximity_marketing"
    ]

    return toneTransactionTypes[toneTransactionType-1]
  }

  function getBillingTypeDisplay(billingType) {
    var billingTypes = {
      "transaction-based": "Transaction Based",
      "flat-fee": "Flat-Fee",
      "freemium": "Freemium"
    }

    return billingTypes[billingType]
  }

  function getToneTransactionTypeDisplay(toneTransactionType) {
    var toneTransactionTypes = {
      "payment": "Payment",
      "identification": "Identification",
      "confirmation": "Confirmation",
      "device_pairing": "Device Pairing",
      "proximity_marketing": "Proximity Marketing"
    }

    return toneTransactionTypes[toneTransactionType];
  }

  return {
    getUserData: getUserData,
    createNewApp: createNewApp,
    editApp: editApp,
    getAppV2: getAppV2,
    getAllApps: getAllApps,
    getUserApps: getUserApps,
    editUserEnabled: editUserEnabled,
    changePassword: changePassword,
    getUsers: getUsers,
    getUserById: getUserById,
    editUser: editUser,
    editUserPassword: editUserPassword,
    createUser: createUser,
    validateUser: validateUser,
    getAppKeys: getAppKeys,
    getAccount: getAccount,
    getInvoices: getInvoices,
    deleteApp: deleteApp,
    getBillingTypeName: getBillingTypeName,
    getToneTransactionTypeName: getToneTransactionTypeName,
    getBillingTypeDisplay: getBillingTypeDisplay,
    getToneTransactionTypeDisplay: getToneTransactionTypeDisplay
  }

});
