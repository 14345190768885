angular.module('FactoriesModule')
  .factory('GroupingFactory', function () {

  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  function _instanceof(left, right) { if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) { return right[Symbol.hasInstance](left); } else { return left instanceof right; } }

  function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      var v = _instanceof(key, Function) ? key(x) : x[key];
      var el = rv.find(function (r) {
        return r && r.key === v;
      });

      if (el) {
        el.values.push(x);
      } else {
        rv.push({
          key: v,
          values: [x]
        });
      }

      return rv;
    }, []);
  }

  return {
    groupBy: groupBy,
    groupByArray: groupByArray
  };
});