angular.module('FactoriesModule')
  .factory('DialogFactory', function ($window, $rootScope, $sce, $timeout) {
  
  var confirmHandler;
  var cancelHandler;
  
  function openDialog(scope, message, cancelButton, confirm, cancel) {
    if($rootScope.authDialog.open === false) {
      document.activeElement.blur();
      $rootScope.navOpen = false;
      $rootScope.dialog.open = true;
      $rootScope.dialog.message = $sce.trustAsHtml(message);
      $rootScope.dialog.cancelButton = cancelButton;
      $rootScope.dialog.dialogProcessing = false;
      confirmHandler = confirm;
      cancelHandler = cancel;
      
      //http://stackoverflow.com/questions/155188/trigger-a-button-click-with-javascript-on-the-enter-key-in-a-text-box
      document.onkeydown = function (evt) {
        var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
        if (keyCode === 13) {
          dialogConfirm();
          scope.$apply();
        }
        if (keyCode === 27) {
          if(cancelButton) {
            dialogCancel();
            scope.$apply();
          }
        } else {
          return true;
        }
      };
    }
  }

  function dialogConfirm() {
    document.onkeydown = function (evt) {};
    confirmHandler();
    $rootScope.dialog.dialogProcessing = true;
  }
  
  function dialogCancel() {
    document.onkeydown = function (evt) {};
    cancelHandler();
    $rootScope.dialog.dialogProcessing = false;
  }
  
  function closeDialog() {
    document.onkeydown = function (evt) {};
    $rootScope.dialog.open = false;
    $rootScope.dialog.dialogProcessing = false;
  }

  function openAppDeleteDialog(scope, confirm, cancel) {
    document.activeElement.blur();
    $rootScope.navOpen = false;
    $rootScope.appDeleteDialog.open = true;
    $rootScope.appDeleteDialog.cancelButton = true;
    $rootScope.appDeleteDialog.dialogProcessing = false;
    $rootScope.appName = '';
    confirmHandler = confirm;
    cancelHandler = cancel;
    
    //http://stackoverflow.com/questions/155188/trigger-a-button-click-with-javascript-on-the-enter-key-in-a-text-box
    document.onkeydown = function (evt) {
      var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
      if (keyCode === 13) {
        appDeleteDialogConfirm();
        scope.$apply();
      }
      if (keyCode === 27) {
        if(cancelButton) {
          appDeleteDialogCancel();
          scope.$apply();
        }
      } else {
        return true;
      }
    };
  }
  
  function appDeleteDialogConfirm(scope) {
    if($rootScope.appName === $rootScope.user.currentapp.name) {
      document.onkeydown = function (evt) {};
      confirmHandler();
      $rootScope.appDeleteDialog.dialogProcessing = true;
    }
  }
  
  function appDeleteDialogCancel() {
    document.onkeydown = function (evt) {};
    cancelHandler();
    $rootScope.appDeleteDialog.dialogProcessing = false;
  }
  
  function appDeleteCloseDialog() {
    document.onkeydown = function (evt) {};
    $rootScope.appDeleteDialog.open = false;
    $rootScope.appDeleteDialog.dialogProcessing = false;
  }

  function openAuthDialog(scope, message, cancelButton, confirm, cancel) {
    document.activeElement.blur();
    document.activeElement.blur();
    $rootScope.navOpen = false;
    $rootScope.authDialog.open = true;
    $rootScope.authDialog.message = $sce.trustAsHtml(message);
    $rootScope.authDialog.cancelButton = cancelButton;
    $rootScope.authDialog.dialogProcessing = false;
    confirmHandler = confirm;
    cancelHandler = cancel;
    
    //http://stackoverflow.com/questions/155188/trigger-a-button-click-with-javascript-on-the-enter-key-in-a-text-box
    document.onkeydown = function (evt) {
      var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
      if (keyCode === 13) {
        authDialogConfirm();
        scope.$apply();
      }
      if (keyCode === 27) {
        if(cancelButton) {
          authDialogCancel();
          scope.$apply();
        }
      } else {
        return true;
      }
    };
  }
  
  function authDialogConfirm(scope) {
    document.onkeydown = function (evt) {};
    confirmHandler();
    $rootScope.authDialog.dialogProcessing = true;
  }
  
  function authDialogCancel() {
    document.onkeydown = function (evt) {};
    cancelHandler();
    $rootScope.authDialog.dialogProcessing = false;
  }
  
  function authCloseDialog() {
    document.onkeydown = function (evt) {};
    $rootScope.authDialog.open = false;
    $rootScope.authDialog.dialogProcessing = false;
  }
  
  return {
    openDialog: openDialog,
    dialogConfirm: dialogConfirm,
    dialogCancel: dialogCancel,
    closeDialog: closeDialog,
    openAppDeleteDialog: openAppDeleteDialog,
    appDeleteDialogConfirm: appDeleteDialogConfirm,
    appDeleteDialogCancel: appDeleteDialogCancel,
    appDeleteCloseDialog: appDeleteCloseDialog,
    openAuthDialog: openAuthDialog,
    authDialogConfirm: authDialogConfirm,
    authDialogCancel: authDialogCancel,
    authCloseDialog: authCloseDialog
  }
  
});