angular.module('FactoriesModule')
  .factory('DeveloperResourcesFactory', function ($http, $rootScope, HttpUtilitiesFactory) {

  function getSDKInfo(platform) {
    var config = {
      params: {
        limit: 100
      }
    };
    if (platform) {
      config.params.platform = platform;
    }
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('sdk-releases').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, config));
  };

  return {
    getSDKInfo: getSDKInfo
  }

});
