angular.module('ControllersModule')
  .controller('AccountInfoController', function ($scope, $rootScope, $route, $routeParams, LoginFactory, AccountFactory, $window, DialogFactory, ChangePasswordFactory, AuthenticationFactory, SDKTypeFactory) {

  $scope.loadingPage = true;
  $scope.loadingError = false;
  $scope.account = [];
  $scope.user = [];

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }

  function getUserDataSuccess(data) {
    $scope.user = data.result;
    $scope.loadingPage = false;
  }

  function getUserDataFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }

  $scope.tzFilter = function(t, filter, timeZone) {
    var time = $window.moment(t);
    return time.tz(timeZone).format(filter);
  };

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
});
