angular.module('FactoriesModule')
  .factory('ToneSpeedFactory', function () {

    var calculateToneSpeed = function (numBytes, profile, includeTonePrivacyId, format, prependSilence, includeTonelock) {
      var sampleRate = 48000;
      var bitsPerSecond;
      var overheadSeconds;

      switch (profile) {
        case 'standard':
        case 'compression':
          bitsPerSecond = 33.333333;
          overheadSeconds = 1.28;
          break;
        case 'standard2':
          bitsPerSecond = 33.333333;
          overheadSeconds = 1.695;
          break;
        case 'standard2_wideband':
          bitsPerSecond = 66.666666;
          overheadSeconds = 0.975;
          break;
        case 'pkab2':
          bitsPerSecond = 1000;
          overheadSeconds = 0.377;
          break;
        case 'pkab2_wideband':
          bitsPerSecond = 2000;
          overheadSeconds = 0.342;
          break;
        case 'zone66':
          bitsPerSecond = 66.666666;
          overheadSeconds = 0.975;
          break;
        case 'point1000':
          bitsPerSecond = 1000;
          overheadSeconds = 0.377;
          break;
        case 'point2000':
          bitsPerSecond = 2000;
          overheadSeconds = 0.342;
          break;
      }

      // Add one byte to overhead for tone privacy ID
      if (includeTonePrivacyId) {
        overheadSeconds += 8 / bitsPerSecond;
      }

      // Add one byte to overhead for tone privacy ID
      if (includeTonelock) {
        switch (profile) {
          case 'point1000':
            overheadSeconds += .078;
            break;
          case 'point2000':
            overheadSeconds += .014;
            break;
        }
      }

      // Add 200ms if prependSilence is true
      if (prependSilence) {
        overheadSeconds += .2;
      }

      var payloadSeconds = (numBytes * ((1 / bitsPerSecond) * 8));
      var totalSeconds = overheadSeconds + payloadSeconds;

      if (format === 'mp3') {
        // LAME adds 576 samples of padding to the beginning and 288 samples of
        // padding to the end of all files. Decoder delay adds 529 samples.
        let totalSamples = Math.round(totalSeconds * sampleRate) + 1393;
        // MP3 frames contain 1152 samples. When decoding, the length of a file
        // will be calculated based on complete frames.
        totalSamples = Math.ceil(totalSamples / 1152) * 1152;
        totalSeconds = totalSamples / sampleRate;
      }

      return {
        overheadSeconds: overheadSeconds,
        payloadSeconds: payloadSeconds,
        totalSeconds: totalSeconds,
      };
    }

    return {
      calculateToneSpeed: calculateToneSpeed
    }
  });
