angular.module('ControllersModule')
.controller('APITokensController', function (SDKTypeFactory, AccountFactory, $sce, $timeout, $scope, $rootScope, AuthenticationFactory, APITokensFactory, LastActivityFactory, DialogFactory, $window, $location, $route) {

  $timeout(function() {
    if($rootScope.user.currentapp === null) {
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
    } else if ($rootScope.user.currentapp.sdk_type === 'point') {
      DialogFactory.openDialog($scope, 'The current app does not support the content on this page.', false, noAppDialogConfirm);
    } else {
      loadAPITokens();
    }
  });

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  var deleteTokenId;
  var accountState;
  $scope.loadingAPITokens = true;
  $scope.APITokens = [];
  $scope.createAPITokenProcessing = false;

  if($rootScope.user.trial === 'true') {
    $scope.accountState = 'trial';
  } else if($rootScope.user.expiredtrial === 'true') {
    $scope.accountState = 'expired-trial';
  } else {
    $scope.accountState = 'live';
  }

  // Load APITokens
  function loadAPITokens() {
    APITokensFactory.getAPITokens($rootScope.user.currentapp.id).then(loadAPITokensSuccess, loadAPITokensFailure);
  }

  function loadAPITokensSuccess(data) {
    $scope.APITokens = $scope.APITokens.concat(data.result);

    var pagination = data.headers['results-next'];

    if (pagination) {
      APITokensFactory.getAPITokens($rootScope.user.currentapp.id, pagination).then(loadAPITokensSuccess, loadAPITokensFailure);
    } else {
      $scope.loadingAPITokens = false;
    }
  }

  function loadAPITokensFailure(data) {
    DialogFactory.openDialog($scope, 'There was an error loading your API Tokens. Please try again later.', false, closeDialogAPITokensFailure);
  }

  // Create APITokens
  $scope.createAPIToken = function() {
    $scope.createAPITokenProcessing = true;
    APITokensFactory.createAPIToken($rootScope.user.currentapp.id).then(createAPITokensSuccess, createAPITokensFailure);
  }

  function createAPITokensSuccess(data) {
    $scope.createAPITokenProcessing = false;
    $scope.APITokens.unshift(data.result);
  }

  function createAPITokensFailure(data) {
    $scope.createAPITokenProcessing = false;
    DialogFactory.openDialog($scope, 'There was an error creating your API Token. Please try again later.', false, closeDialogAPITokensFailure);
  }

  // Delete APITokens
  $scope.deleteAPIToken = function(event, token) {
    event.stopPropagation();
    $scope.deleteTokenId = token.id;
    DialogFactory.openDialog($scope, 'Are you sure you want to delete this token? Anything using this token will no longer authenticate to our API.', true, $scope.deleteAPITokenDialogConfirm, $scope.deleteAPITokenDialogCancel);
  }

  $scope.deleteAPITokenDialogConfirm = function() {
    APITokensFactory.deleteAPIToken($rootScope.user.currentapp.id, $scope.deleteTokenId).then(deleteAPITokensSuccess, deleteAPITokensFailure);
  }

  $scope.deleteAPITokenDialogCancel = function() {
    DialogFactory.closeDialog();
  }

  function deleteAPITokensSuccess(data) {
    $scope.APITokens = $scope.APITokens.filter(function(token) {
      return token.id != $scope.deleteTokenId
    });
    DialogFactory.closeDialog();
  }

  function deleteAPITokensFailure(data) {
    DialogFactory.closeDialog();
    DialogFactory.openDialog($scope, 'There was an error deleting your API Token. Please try again later.', false, closeDialogAPITokensFailure);
  }

  function closeDialogAPITokensFailure() {
    DialogFactory.closeDialog();
  }

  $scope.lastActivityDisplay = LastActivityFactory.lastActivityDisplay;

  var clipboardJWT = new Clipboard('#api-token-copy-button');
  clipboardJWT.on('success', function (event) {
    $(document).find('.copy-message').empty();
    $(event.trigger).parent().parent().find('p.copy-message')[0].innerHTML = 'Your token has been successfully copied.';
    event.clearSelection();
  });

  $scope.getBillingTypeDisplay = function(billingType) {
    return AccountFactory.getBillingTypeDisplay(billingType);
  }

  $scope.getToneTransactionTypeDisplay = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeDisplay(toneTransactionType);
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
});
