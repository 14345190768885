angular.module('ControllersModule')
  .controller('AnalyticsController', function ($sce, $location, $rootScope, AuthenticationFactory, $scope, DialogFactory, AnalyticsFactory, $timeout) {

  $timeout(function() {
    if(!$rootScope.settingAppFromUrl) {
      if($location.path().slice(0,6) === '/apps/') {
        if($rootScope.user.currentapp === null) {
          DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
        } else if($rootScope.user.currentapp.type === 'offline') {
          DialogFactory.openDialog(null, "Application is in offline mode. Redirecting to home page.", false, noAppDialogConfirm);
        } else if($rootScope.user.currentapp.sdk_type === 'radius') {
          DialogFactory.openDialog(null, "The current app does not support the content on this page.", false, noAppDialogConfirm);
        } else {
          $scope.getAnalyticsDashboardUrl();
        }
      }
    }
  });

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.analyticsDashboardUrl = "";
  $scope.loadingAnalyticsDashboardUrl = true;

  $scope.getAnalyticsDashboardUrl = function() {
    $scope.loadingAnalyticsDashboardUrl = true;
    $scope.analyticsDashboardUrlError = false;
    AnalyticsFactory.getAnalyticsDashboardUrl().then(getAnalyticsDashboardUrlSuccess, getAnalyticsDashboardUrlFailure);
  }

  function getAnalyticsDashboardUrlSuccess(data) {
    $scope.loadingAnalyticsDashboardUrl = false;
    $scope.analyticsDashboardUrl = $sce.trustAsResourceUrl(data.result.analytics_dashboard_url)
  }

  function getAnalyticsDashboardUrlFailure(data) {
    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field === '__all__') {
        loadingAnalyticsDashboardUrlError();
      } else {
        loadingAnalyticsDashboardUrlError();
      }
    } catch (error) {
      loadingAnalyticsDashboardUrlError();
    }
  }

  function loadingAnalyticsDashboardUrlError() {
    $scope.loadingAnalyticsDashboardUrl = false;
    $scope.analyticsDashboardUrlError = true;
  }

  $scope.refreshDashboard = function() {
    document.getElementById('analytics-frame').contentWindow.postMessage({event_type: "refresh_charts"}, "*");
  }
});
