angular.module('ControllersModule')
  .controller('DeveloperResourcesController', function ($route, DeveloperResourcesFactory, DialogFactory, $parse, $scope, IntercomFactory) {

  $scope.loadingDeveloperInfo = true;
  $scope.copied = false;
  $scope.copyMessage = 'Copy to clipboard';

  var clipboard = new Clipboard('#clipboard');
  clipboard.on('success', function (event) {
    event.clearSelection();
    $scope.copied = true;
    $scope.copyMessage = 'Copied!';
    $scope.$apply();
  });

  clipboard.on('error', function (event) {
    SelectText('clipboard');
    $scope.copied = true;
    $scope.copyMessage = 'Press Ctrl+C to copy';
    $scope.$apply();
  });

  function SelectText(element) {
    var doc = document,
      text = doc.getElementById(element),
      range, selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  DeveloperResourcesFactory.getSDKInfo().then(getSDKInfoSuccess, getSDKInfoFailure);

  function getSDKInfoSuccess(data) {
    var releasesLength = data.data.releases.length;

    for (var i = 0; i < releasesLength; i++) {
      var model = $parse(data.data.releases[i].platform);
      model.assign($scope, data.data.releases[i]);
    }

    $scope.loadingDeveloperInfo = false;
  }

  function getSDKInfoFailure(data) {
    $scope.loadingDeveloperInfo = false;
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, getSDKInfoFailureDialogConfirm);
  }

  function getSDKInfoFailureDialogConfirm() {
    $route.reload();
    DialogFactory.closeDialog();
  }

  function closeDialog() {
    DialogFactory.closeDialog();
  }

  $scope.sdkDownload = function(platform) {
    if(platform === 'ios') {
      IntercomFactory.updateObject({iossdkdownloaded: true});
    } else if(platform === 'android') {
      IntercomFactory.updateObject({androidsdkdownloaded: true});
    }
  }

  $scope.docsDownload = function(platform) {
    if(platform === 'ios') {
      IntercomFactory.updateObject({iosdocsdownloaded: true});
    } else if(platform === 'android') {
      IntercomFactory.updateObject({androiddocsdownloaded: true});
    }
  }

  $scope.viewDocs = function() {
    IntercomFactory.updateObject({viewedapidocs: true});
  }
});
