angular.module('FactoriesModule')
  .factory('IntercomFactory', function ($rootScope) {
  
  var isBooted = false;
  
  function isLisnr(username) {
    return username.match(/@lisnr\.com/i) === null ? false : true;
  }
  
  function boot(login) {
    if(!isLisnr($rootScope.user.email)) {
      if(!isBooted) {
        if(window.Intercom) {
          isBooted = true;
          window.Intercom('boot', {
            app_id: intercomId,
            email: $rootScope.user.email,
            user_id: $rootScope.user.userid,
            company: {
              company_id: $rootScope.user.accountid,
            },
            widget: {
              activator: '#IntercomDefaultWidget'
            }
          });
        } else {
          update();
        }
      }
    } 
  }
  
  function update() {
    if(isBooted) {
      window.Intercom('update');
    }
  }
  
  function updateObject(object) {
    if(isBooted) {
      window.Intercom('update', object);
    }
  }

  function trackEvent(event) {
    if(isBooted) {
      window.Intercom('trackEvent', event);
    }
  }
  
  function shutdown() {
    if(isBooted) {
      isBooted = false;
      window.Intercom('shutdown');
    }
  }

  return {
    boot: boot,
    shutdown: shutdown,
    updateObject: updateObject,
    trackEvent: trackEvent
  }
});