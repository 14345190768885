angular.module('FactoriesModule')
  .factory('NotificationsFactory', function ($http, $rootScope, HttpUtilitiesFactory, URLUtilitiesFactory) {

  function getNotifications(pagination) {
    return _getNotificationsWithParams({'pagination': pagination});
  };

  function _getNotificationsWithParams(query) {
    if ('pagination' in query) {
      var limit = URLUtilitiesFactory.extractParameterByName('limit', query['pagination']);
      var offset = URLUtilitiesFactory.extractParameterByName('offset', query['pagination']);
      delete query['pagination'];

      query.limit = limit;
      query.offset = offset;

      if (isNaN(limit)) {
        delete query.limit;
      }

      if (isNaN(offset)) {
        delete query.offset;
      }
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+$rootScope.user.userid+'/notifications/').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {'params':query}));
  };

  function markNotificationRead(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+$rootScope.user.userid+'/notifications/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.patch(url, {
      is_read: true
    }));
  };

  function dismissNotification(id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('users/'+$rootScope.user.userid+'/notifications/'+id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.patch(url, {
      is_dismissed: true
    }));
  };

  return {
    getNotifications: getNotifications,
    markNotificationRead: markNotificationRead,
    dismissNotification: dismissNotification
  }

});
