angular.module('ControllersModule')
  .controller('ApiTokensCurrentController', function ($timeout, $rootScope, $scope, AuthenticationFactory, DialogFactory, $location) {

  $timeout(function() {
    if($rootScope.user.currentapp === null) {
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
    } else {
      $location.replace();
      $location.url('/apps/' + $rootScope.user.currentapp.id + '/api-tokens/');
    }
  });

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }
});
