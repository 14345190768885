angular.module('ControllersModule')
  .controller('ToneProfilesController', function (AccountFactory, $scope, $rootScope, $location) {

  $scope.account = [];
  $scope.loadingPage = true;

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    $scope.loadingPage = false;
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, getSDKInfoFailureDialogConfirm);
  }
});