angular.module('ControllersModule')
.controller('CreateSDKTokenController', function ($sce, $scope, $location, $rootScope, $timeout, AuthenticationFactory, SDKTokensFactory, DialogFactory) {

  $timeout(function() {
    if($rootScope.user.currentapp === null) {
      DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
    } else if ($rootScope.user.currentapp.sdk_type === 'legacy') {
      DialogFactory.openDialog($scope, 'The current app does not support the content on this page.', false, noAppDialogConfirm);
    } else {
      if($rootScope.user.trial === 'true') {
        $scope.accountState = 'trial';
      } else if($rootScope.user.expiredtrial === 'true') {
        $scope.accountState = 'expired-trial';
      } else {
        $scope.accountState = 'live';
      }
    }
  });

  $scope.sdkTokenInput = {
    apple_bundle_ids: [''],
    android_application_ids: [''],
  }
  $scope.createSDKTokenProcessing = false;
  $scope.createSDKTokenError = false;
  $scope.createSDKTokenErrorMessage = '';

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.createSDKToken = function() {
    $scope.createSDKTokenError = false;
    $scope.bundleIdErrors = [];
    $scope.applicationIdErrors = [];

    if(!$scope.createSDKTokenProcessing) {
      if(!validateBundleIds() || !validateApplicationIds()) {
        $scope.createSDKTokenError = true;
        return;
      }
      $scope.createSDKTokenProcessing = true;

      // creates a deep copy of sdkTokenInput
      var data = angular.copy($scope.sdkTokenInput)

      // Remove empty bundle IDs and application IDs
      data.apple_bundle_ids = data.apple_bundle_ids.filter((bundle_id) => bundle_id.length > 0)
      data.android_application_ids = data.android_application_ids.filter((application_id) => application_id.length > 0)
      
      // create platform_whitelist_ids from apple bundle ids and android application ids
      data.platform_whitelist_ids = data.apple_bundle_ids.concat(data.android_application_ids);

      SDKTokensFactory.createSDKToken($rootScope.user.currentapp.id, data).then(createSDKTokenSuccess, createSDKTokenFailure);
    }
  }

  $scope.addBundleId = function() {
    if($scope.sdkTokenInput.apple_bundle_ids.length < 10) {
      $scope.sdkTokenInput.apple_bundle_ids.push("");
    }
  }

  $scope.removeBundleId = function(index) {
    $scope.sdkTokenInput.apple_bundle_ids.splice(index, 1);
    $scope.bundleIdErrors.splice(index, 1);
  }

  $scope.addApplicationId = function() {
    if($scope.sdkTokenInput.android_application_ids.length < 10) {
      $scope.sdkTokenInput.android_application_ids.push("");
    }
  }

  $scope.removeApplicationId = function(index) {
    $scope.sdkTokenInput.android_application_ids.splice(index, 1);
    $scope.applicationIdErrors.splice(index, 1);
  }

  function createSDKTokenSuccess(data) {
    $scope.createSDKTokenProcessing = false;
    $location.path('/apps/'+$rootScope.user.currentapp.id+'/sdk-tokens/');
  }

  function createSDKTokenFailure() {
    $scope.createSDKTokenProcessing = false;
    DialogFactory.openDialog($scope, 'There was an error creating your SDK Token. Please try again later.', false, closeDialogSDKTokensFailure);
  }

  function closeDialogSDKTokensFailure() {
    DialogFactory.closeDialog();
  }

  function validateBundleIds() {
    const regex = /^[A-Z0-9\.\-]+$/i;
    for(var i=0;i<$scope.sdkTokenInput.apple_bundle_ids.length;i++) {
      const bundleId = $scope.sdkTokenInput.apple_bundle_ids[i];
      if(bundleId.length > 0 && !regex.test(bundleId)) {
        $scope.createSDKTokenErrorMessage = $sce.trustAsHtml(
          'Error: Apple Bundle ID may contain only letters, numbers, periods, and dashes. ' +
          'Information on retrieving your Bundle ID can be found <a href="/developer-resources/ios/apple-bundle-id">here</a>.'
        );
        $scope.bundleIdErrors[i] = true;
        return false;
      }
    }
    return true;
  }

  function validateApplicationIds() {
    const regex = /^[A-Z]+\w*\.([A-Z]+\w*\.?)+$/i;
    for(var i=0;i<$scope.sdkTokenInput.android_application_ids.length;i++) {
      const applicationId = $scope.sdkTokenInput.android_application_ids[i];
      if(applicationId.length > 0 && !regex.test(applicationId)) {
        $scope.createSDKTokenErrorMessage = $sce.trustAsHtml(
          'Error: Android Application ID may contain only letters, numbers, and underscores. ' +
          'The ID must have at least two segments separated by a period. ' +
          'Each segment must start with a letter. ' +
          'Information on retrieving your Application ID can be found <a href="/developer-resources/android/android-application-id">here</a>.'
        );
        $scope.applicationIdErrors[i] = true;
        return false;
      }
    }
    return true;
  }
});
