angular
  .module("ControllersModule")
  .controller(
    "ToneCreatorController",
    function (
      $scope,
      $rootScope,
      $timeout,
      $location,
      ToneCreatorFactory,
      AuthenticationFactory,
      DialogFactory,
      ToneSpeedFactory,
      AccountFactory,
      APITokensFactory
    ) {
      $timeout(function () {
        if ($rootScope.user.currentapp === null) {
          DialogFactory.openDialog(
            $scope,
            "You must select an app to view the content on this page.",
            false,
            noAppDialogConfirm
          );
        } else if (
          $rootScope.user.currentapp.sdk_type !== "legacy" &&
          $rootScope.user.currentapp.sdk_type !== "radius" &&
          $rootScope.user.currentapp.sdk_type !== "radius3"
        ) {
          DialogFactory.openDialog(
            $scope,
            "The current app does not support the content on this page.",
            false,
            noAppDialogConfirm
          );
        } else {
          getApiTokens();
        }
      });

      function noAppDialogConfirm() {
        DialogFactory.closeDialog();
        AuthenticationFactory.homepageRedirect();
      }

      function noApiTokenDialogConfirm() {
        DialogFactory.closeDialog();
        $location.path(
          "/apps/" + $rootScope.user.currentapp.id + "/api-tokens/"
        );
      }

      function loadFailure() {
        $scope.loadingPage = false;
        $scope.loadingError = true;
      }

      function getApiTokens() {
        const pagination = {
          limit: 1,
        };
        APITokensFactory.getAPITokens(
          $rootScope.user.currentapp.id,
          pagination
        ).then(getApiTokensSuccess, loadFailure);
      }

      function getApiTokensSuccess(data) {
        const tokens = data.result;
        if (tokens.length > 0) {
          apiToken = tokens[0];
          AccountFactory.getAccount().then(getAccountSuccess, loadFailure);
        } else {
          DialogFactory.openDialog(
            $scope,
            "Please create an API token before attempting to create a tone. Click OK to visit the API Tokens page.",
            false,
            noApiTokenDialogConfirm
          );
        }
      }

      function getAccountSuccess(data) {
        $scope.account = data.result[0];
        $scope.loadingPage = false;

        var defaultProfile;

        switch ($rootScope.user.currentapp.sdk_type) {
          case "legacy":
            defaultProfile = "standard";
            break;
          case "radius":
            defaultProfile = "pkab2";
            break;
          case "radius3":
            defaultProfile = "zone66";
            break;
          default:
            return "zone66";
        }

        $scope.newTone = {
          type: "data",
          profile: defaultProfile,
          payload: "",
          chime: false,
          format: "wav",
          zip: true,
          prependSilence: false,
          channel: "1",
          tonelock: "",
        };
      }

      // Profiles supported by each SDK type
      const supportedProfiles = {
        legacy: ["standard", "compression"],
        radius: [
          "pkab2",
          "pkab2_wideband",
          "standard2",
          "standard2_wideband",
        ],
        radius3: [
          "zone66",
          "point1000",
          "point2000",
        ],
      };

      $scope.formatTransactionType = function() {
        switch ($rootScope.user.currentapp.tone_transaction_type) {
          case "payment":
            return "Payment";
            break;
          case "identification":
            return "Identification";
            break;
          case "confirmation":
            return "Confirmation";
            break;
          case "device_pairing":
            return "Device Pairing";
            break;
          case "proximity_marketing":
            return "Proximity Marketing";
            break;
        }
      }

      $scope.isProfileSupported = function (profile) {
        const sdkSupportedProfiles =
          supportedProfiles[$rootScope.user.currentapp.sdk_type];
        if (sdkSupportedProfiles === undefined) {
          return false;
        }

        return sdkSupportedProfiles.indexOf(profile) !== -1;
      };

      $scope.formatProfile = function (profile) {
        switch (profile) {
          case "pkab2":
            return "PKAB 2.0";
            break;
          case "pkab2_wideband":
            return "PKAB 2.0 Wideband";
            break;
          case "standard":
            return "Standard";
            break;
          case "standard2":
            return "Standard 2.0";
            break;
          case "standard2_wideband":
            return "Standard 2.0 Wideband";
            break;
          case "compression":
            return "Compression";
            break;
          case "zone66":
            return "Zone66";
            break;
          case "point1000":
            return "Point1000";
            break;
          case "point2000":
            return "Point2000";
            break;
          default:
            return "-";
        }
      };

      var preview = false;
      var previewBlob;
      var previewObjectURL;
      var blobFilename;
      var blobFileType;
      var blobBlobType;
      var downloadBlob;
      var downloadObjectURL;
      var apiToken = null;

      $scope.loadingPage = true;
      $scope.loadingError = false;
      $scope.account = [];

      $scope.length = 0;
      $scope.creatingTone = false;
      $scope.previewIsCurrent = false;
      $scope.toneError = false;
      $scope.toneErrorMessage = "";

      $scope.previewTone = {
        type: "-",
        profile: "-",
        payload: "-",
        chime: false,
        format: "-",
        zip: true,
        prependSilence: false,
        channel: "1",
        tonelock: "",
      };

      $scope.downloadTone = {
        type: "-",
        profile: "-",
        payload: "-",
        chime: false,
        format: "-",
        zip: true,
        prependSilence: false,
        channel: "1",
        tonelock: "",
      };

      $scope.$watch(
        "newTone",
        function (newVal, oldVal) {
          // Skip during initialization
          if (newVal === oldVal) {
            return;
          }

          $scope.toneError = false;
          $scope.toneErrorMessage = "";
          updateToneLength();
          checkIfTonesAreCurrent();
          updateDefaultChannel();
          updateDefaultTonelock();
        },
        true
      );

      function updateDefaultChannel() {
        if($scope.newTone.profile === 'point2000') {
          $scope.newTone.channel = '1';
        }
      }

      function updateDefaultTonelock() {
        if($scope.newTone.profile !== 'point1000' && $scope.newTone.profile !== 'point2000') {
          $scope.newTone.tonelock = '';
        }
      }

      function checkIfTonesAreCurrent() {
        if (angular.equals($scope.newTone, $scope.previewTone)) {
          $scope.previewIsCurrent = true;
        } else {
          $scope.previewIsCurrent = false;
        }
        if (angular.equals($scope.newTone, $scope.downloadTone)) {
          $scope.downloadIsCurrent = true;
        } else {
          $scope.downloadIsCurrent = false;
        }
      }

      function updateToneLength() {
        if (typeof $scope.newTone.payload === "undefined") {
          $scope.length = "-";
          return;
        }

        var numBytes = $scope.newTone.payload.length;
        if (numBytes === 0) {
          $scope.length = "-";
          return;
        }

        if ($scope.newTone.type === "data") {
          numBytes /= 2;
        }

        const profile = $scope.newTone.profile;
        const includeTonePrivacyId = $scope.account.tone_privacy_id !== null;
        const includeTonelock = $scope.newTone.tonelock !== "" && $scope.newTone.tonelock !== undefined;
        const format = $scope.newTone.format;
        const prependSilence = $scope.newTone.prependSilence;
        const results = ToneSpeedFactory.calculateToneSpeed(
          numBytes,
          profile,
          includeTonePrivacyId,
          format,
          prependSilence,
          includeTonelock
        );

        $scope.length = results.totalSeconds.toFixed(3);
      }

      $scope.toggleChime = function () {
        $scope.newTone.chime = !$scope.newTone.chime;
      };

      $scope.previewToneSubmit = function () {
        preview = true;
        ToneCreatorFactory.validateToneProperties(
          $scope.newTone,
          tonePropertiesValid,
          tonePropertiesInvalid
        );
      };

      $scope.downloadToneSubmit = function () {
        if ($scope.downloadIsCurrent) {
          saveAs(downloadBlob, blobFilename);
        } else {
          ToneCreatorFactory.validateToneProperties(
            $scope.newTone,
            tonePropertiesValid,
            tonePropertiesInvalid
          );
        }
      };

      $scope.prependSilenceCheckboxToggle = function () {
        $scope.newTone.prependSilence = !$scope.newTone.prependSilence;
      };

      // Get the Tones Service request parameters for a tone.
      //
      // For tones with 'text' type, encode the ASCII payload as UTF-8 and convert
      // to a hexadecimal string representation.
      //
      // Note: could use TextEncoder and String.padStart(), but would need
      // polyfills.
      function getCreateToneParams(tone) {
        const params = Object.assign({}, tone);

        if (params.type === "text") {
          const hexStrings = [];
          for (var i = 0; i < params.payload.length; i++) {
            // Convert ASCII character to hexadecimal string representation
            const charCode = params.payload.charCodeAt(i);
            const hexString = charCode.toString(16);

            // Zero pad
            if (hexString.length < 2) {
              hexStrings.push("0");
            }

            hexStrings.push(hexString);
          }

          params.payload = hexStrings.join("");
        }

        delete params.type;

        return params;
      }

      function tonePropertiesValid() {
        $scope.toneError = false;
        $scope.creatingTone = true;

        const params = getCreateToneParams($scope.newTone);

        ToneCreatorFactory.createTone(params, apiToken).then(
          createToneSuccess,
          createToneFailure
        );
      }

      function tonePropertiesInvalid(errorMessage) {
        $scope.toneError = true;
        $scope.toneErrorMessage = errorMessage;
      }

      function createToneSuccess(data) {
        if (preview) {
          Object.assign($scope.previewTone, $scope.newTone);
          loadAudioFile(data.url);
          checkIfTonesAreCurrent();
        } else {
          Object.assign($scope.downloadTone, $scope.newTone);
          loadAudioFile(data.url);
          checkIfTonesAreCurrent();
          $scope.creatingTone = false;
        }
      }

      function createToneFailure(data) {
        DialogFactory.openDialog(
          $scope,
          "There was an error creating your tone. Please try again.",
          false,
          closeDialog
        );
      }

      function closeDialog() {
        DialogFactory.closeDialog();
        preview = false;
        $scope.creatingTone = false;
        $scope.previewTone = {
          type: "-",
          profile: "-",
          payload: "-",
          format: "-",
          zip: true,
          channel: "1",
          tonelock: "",
        };
        $scope.downloadTone = {
          type: "-",
          profile: "-",
          payload: "-",
          format: "-",
          zip: true,
          channel: "1",
          tonelock: "",
        };
        checkIfTonesAreCurrent();
      }

      function loadAudioFile(audioFileUrl) {
        fetch(audioFileUrl)
          .then(function (response) {
            if (response.status === 200 || response.status === 0) {
              return Promise.resolve(response.blob());
            } else {
              DialogFactory.openDialog(
                $scope,
                "There was an error creating your tone. Please try again.",
                false,
                closeDialog
              );
              return Promise.reject(new Error(response.statusText));
            }
          })
          .then(JSZip.loadAsync)
          .then(function (zip) {
            var filename;

            for (var property in zip.files) {
              filename = property;
            }

            blobFilename = filename;
            blobFileType = blobFilename.slice(
              blobFilename.length - 3,
              blobFilename.length
            );

            switch (blobFileType) {
              case "wav":
                blobBlobType = "audio/wav";
                break;
              case "mp3":
                blobBlobType = "audio/mpeg";
                break;
            }

            return zip.file(filename).async("blob");
          })
          .then(
            function success(blob) {
              blob = blob.slice(0, blob.size, blobBlobType);
              if (preview) {
                var audio = document.getElementById("tone-preview-audio");
                var source = document.getElementById("tone-preview-source");
                previewBlob = blob;
                previewObjectURL = URL.createObjectURL(blob);
                source.type = blobBlobType;
                source.src = previewObjectURL;
                audio.load();
                preview = false;
              } else {
                downloadBlob = blob;
                downloadObjectURL = URL.createObjectURL(blob);
                saveAs(downloadBlob, blobFilename);
              }
              $scope.creatingTone = false;
              $scope.$apply();
            },
            function error(error) {
              DialogFactory.openDialog(
                $scope,
                "There was an error creating your tone. Please try again.",
                false,
                closeDialog
              );
            }
          );
      }
    }
  );
