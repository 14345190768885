angular.module('FactoriesModule')
  .factory('SDKTokensFactory', function ($http, $rootScope, HttpUtilitiesFactory, URLUtilitiesFactory) {

  function getSDKTokens(id, pagination) {
    var params = {};

    if (pagination) {
      var limit = URLUtilitiesFactory.extractParameterByName('limit', pagination);
      var offset = URLUtilitiesFactory.extractParameterByName('offset', pagination);

      if (!isNaN(limit)) {
        params.limit = limit;
      }

      if (!isNaN(offset)) {
        params.offset = offset;
      }
    }

    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/sdk-tokens').toString();
    return HttpUtilitiesFactory._transformResponseData($http.get(url, {'params': params}));
  };

  function createSDKToken(id, data) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/sdk-tokens').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, data));
  };

  function deleteSDKToken(id, token_id) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('apps/'+id+'/sdk-tokens/'+token_id).toString();
    return HttpUtilitiesFactory._transformResponseData($http.delete(url));
  };

  return {
    getSDKTokens: getSDKTokens,
    createSDKToken: createSDKToken,
    deleteSDKToken: deleteSDKToken,
  }
});
