angular.module('ControllersModule')
  .controller('WindowsController', function (AccountFactory, $scope, $parse, DeveloperResourcesFactory, DialogFactory, IntercomFactory, GroupingFactory, SDKTypeFactory) {

  $scope.account = [];
  $scope.sdks = {
    legacy: [],
    radius: [],
    radius3: [],
    point: [],
    sda: []
  };
  $scope.selectedSdk = {
    legacy: {},
    radius: {},
    radius3: {},
    point: {},
    sda: {}
  };
  $scope.loadingDeveloperInfo = true;
  $scope.isPlatformSdkAvailable = true;

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];
    DeveloperResourcesFactory.getSDKInfo('windows').then(getSDKInfoSuccess, getSDKInfoFailure);
  }

  function getAccountFailure(data) {
    $scope.loadingDeveloperInfo = false;
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, getSDKInfoFailureDialogConfirm);
  }

  function getSDKInfoSuccess(data) {
    var platformReleases = data.result;
    var sortedPlatformReleases = platformReleases.sort(function (a, b) {
      return b.version.localeCompare(a.version, undefined, {
        numeric: true
      });
    });
    var productReleases = GroupingFactory.groupBy(sortedPlatformReleases, 'sdk_type');
    for (var product in productReleases) {
      if (productReleases.hasOwnProperty(product)) {
        var currentProductReleases = productReleases[product];
        var architectureSortedProductReleases = GroupingFactory.groupByArray(currentProductReleases, 'architecture');
        for (var i = 0; i < architectureSortedProductReleases.length; i++) {
          $scope.sdks[product].push(architectureSortedProductReleases[i].values[0]);
        }
        $scope.selectedSdk[product] = $scope.sdks[product][0];
      }
    }

    if($scope.sdks.legacy.length < 1 && $scope.sdks.radius.length < 1 && $scope.sdks.point.length < 1 && $scope.sdks.sda.length < 1) {
      $scope.isPlatformSdkAvailable = false;
    }

    $scope.loadingDeveloperInfo = false;
  }

  function getSDKInfoFailure(data) {
    $scope.loadingDeveloperInfo = false;
    DialogFactory.openDialog($scope, 'There was an error loading the page. Click OK to reload the page.', false, getSDKInfoFailureDialogConfirm);
  }

  function getSDKInfoFailureDialogConfirm() {
    $route.reload();
    DialogFactory.closeDialog();
  }

  function closeDialog() {
    DialogFactory.closeDialog();
  }

  $scope.selectSdk = function(product, sdk) {
    $scope.selectedSdk[product] = sdk;
  }

  $scope.trackSDKDownload = function(sdk) {
    IntercomFactory.trackEvent('sdkdownloaded-'+sdk.platform+'-'+sdk.version);
  }

  $scope.trackSDKDocsView = function(sdk) {
    IntercomFactory.trackEvent('sdkdocsviewed-'+sdk.platform+'-'+sdk.version);
  }

  $scope.getSDKTypeDisplayName = SDKTypeFactory.getDisplayName;
});
