angular.module('ControllersModule')
  .controller('DeveloperResourcesPageController', function ($scope, DialogFactory, $rootScope, $location) {

  if($rootScope.user.expiredtrial === 'true') {
    DialogFactory.openDialog($scope, 'Your trial has expired and you can no longer access this page. Please check with your admin for more information', false, closeDialog);
  }

  function closeDialog() {
    DialogFactory.closeDialog();
    $location.path('/help-center/');
  }
});