angular.module('FactoriesModule')
  .factory('ChangePasswordFactory', function ($http, $rootScope, HttpUtilitiesFactory) {

  var specialCharacterRegex = /[\[\]\^\$\.\|\?\*\+\(\)\\~`\!@#%&\-_+={}'""<>:;, ]{1,}/;
  var numberRegex = /[0-9]/;

  function validatePasswords(passwords) {
    if(passwords[0] !== passwords[1]) {
      return 'Passwords do not match.';
    }

    if(!passwords[1].match(numberRegex)){
      return 'Password does not contain a number.';
    }

    if(!passwords[1].match(specialCharacterRegex)){
      return 'Password does not contain a special character.';
    }

    if(passwords[1].length < 6) {
      return 'Password is too short.';
    }

    if(passwords[1].length > 25) {
      return 'Password is too long.';
    } else {
      return null;
    }
  }

  function changePassword(passwords) {
    var url = new URI($rootScope.apiBaseV2);
    url = url.segment('auth/password/change').toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      current_password: passwords[0],
      new_password: passwords[1]
    }));
  };

  function forgotPassword(token, email, password, success, failure) {
    var url = new URI($rootScope.authURL);
    url = url.segment('password/reset/'+token).toString();
    return HttpUtilitiesFactory._transformResponseData($http.post(url, {
      email: email,
      password: password
    }));
  };

  return {
    validatePasswords: validatePasswords,
    changePassword: changePassword,
    forgotPassword: forgotPassword
  }

});
