angular.module('ControllersModule')
  .controller('LoginController', function ($window, $location, $rootScope, $scope, LoginFactory, AuthenticationFactory, AccountFactory, ChangePasswordFactory, $timeout, DialogFactory) {

  //remove any query strings from url
  $location.url($location.path());

  $scope.user = [];

  $scope.login = true;
  $scope.loginUser = [];
  $scope.loginUser.remember = true;
  $scope.loginProcessing = false;
  $scope.loginError = false;

  $scope.forgotPasswordInfo = [];
  $scope.forgotPassword = false;
  $scope.forgotPasswordProcessing = false;
  $scope.forgotPasswordError = false;
  $scope.forgotPasswordSuccess = false;

  $scope.changePassword = false;
  $scope.userAgreement = false;

  $scope.passwords = [];
  $scope.changePasswordProcessing = false;
  $scope.changePasswordError = false;

  $scope.agreeInfo = [];
  $scope.agreeProcessing = false;
  $scope.agreeError = false;

  $scope.trial = false;
  $scope.expiredTrial = false;
  $scope.expiredTrialDisplay = false;

  $scope.forgotPasswordToggle = function () {
    $scope.login = !$scope.login;
    $scope.forgotPassword = !$scope.forgotPassword;
    $scope.forgotPasswordError = false;
    $scope.forgotPasswordErrorMessage = '';
    $scope.loginError = false;
    $scope.loginErrorMessage = '';
  }

  $scope.rememberMeToggle = function () {
    $scope.loginUser.remember = !$scope.loginUser.remember;
  }

  $scope.agreeCheckboxToggle = function () {
    $scope.agreeCheckbox = !$scope.agreeCheckbox;
  }

  $scope.forgotPasswordSubmit = function(validUsername) {
    if(!validUsername) {
      $scope.forgotPasswordError = true;
      $scope.forgotPasswordErrorMessage = 'Please provide a valid username or email.';
      return;
    } else {
      $scope.forgotPasswordError = false;
      $scope.forgotPasswordErrorMessage = '';
    }
    if(!$scope.forgotPasswordProcessing && !$scope.forgotPasswordSuccess) {
      $scope.forgotPasswordProcessing = true;
      LoginFactory.forgotPassword($scope.forgotPasswordInfo.username).then(forgotPasswordSuccess, forgotPasswordFailure);
    }
  }

  function forgotPasswordSuccess(data) {
    $scope.forgotPasswordProcessing = false;
    $scope.forgotPasswordSuccess = true;
    $timeout(goBackToLogin, 2000);
  }

  function forgotPasswordFailure(data) {
    $scope.forgotPasswordProcessing = false;
    $scope.forgotPasswordError = true;
    $scope.forgotPasswordErrorMessage = 'There was an error. Please try again.';
  }

  function goBackToLogin() {
    $scope.forgotPasswordSuccess = false;
    $scope.forgotPasswordToggle();
  }

  $scope.loginSubmit = function (validUsername, validPassword) {
    if(!$scope.loginProcessing) {
      if(validUsername && validPassword) {
        $scope.loginError = false;
        $scope.loginErrorMessage = '';
      } else {
        $scope.loginError = true;
        $scope.loginErrorMessage = 'Please provide all credentials.';
        return;
      }

      $scope.loginProcessing = true;
      $scope.user = $scope.loginUser;
      $scope.user.remember = $scope.loginUser.remember;
      LoginFactory.login($scope.loginUser).then(loginSuccess, loginFailure);
    }
  }

  function loginSuccess(data) {
    $scope.user.token = data.result.token;
    $scope.user.changepassword = data.result.is_password_change_required;
    $scope.user.agreerequired = !data.result.is_agreement_accepted;
    $rootScope.user.token = $scope.user.token;

    if($scope.user.changepassword) {
      $scope.login = false;
      $scope.loginProcessing = false;
      $scope.changePassword = true;
    } else if($scope.user.agreerequired) {
      $scope.login = false;
      $scope.loginProcessing = false;
      $scope.userAgreement = true;
    } else {
      LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
    }
  }

  function loginFailure(data) {
    $scope.loginProcessing = false;
    $scope.loginError = true;
    if(data.status === -1) { //when the options call fails
      $scope.loginErrorMessage = "The server is down. Please try again. If you continue to experience issues with login please contact techsupport@lisnr.com";
    } else  if(data.status === 403) {
      $scope.loginErrorMessage = data.data.result.message;
      if(data.data.result.errorCode === 0) {
        $scope.login = false;
        $scope.suspended = true;
      }
    } else if(data.status === 401) {
      $scope.loginErrorMessage = data.data.result.message;
    } else if(data.status === 502) {
      $scope.loginErrorMessage = "The server is down. Please try again. If you continue to experience issues with login please contact techsupport@lisnr.com";
    } else {
      $scope.loginErrorMessage = "Oops, something went wrong. If you continue to experience issues with login please contact techsupport@lisnr.com"
    }
  }

  $scope.expiredTrialContinue = function() {
    $scope.loginProcessing = true;
    setLoggedIn();
  }

  $scope.supportMail = function(){
    $window.open("mailto:techsupport@lisnr.com");
  }

  function getUserDataSuccess(data) {
    $scope.login = false;
    $scope.loginProcessing = false;
    $scope.user.userid = data.result.id;
    $scope.user.accountid = data.result.account.id;
    $scope.user.email = data.result.email;
    $scope.user.user_type = data.result.user_type;
    if($scope.user.email == null) {
      $scope.user.email = '';
    }
    $scope.user.isAdmin = data.result.is_account_admin;
    $scope.user.currentapp = null;

    AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);
  }

  function getUserDataFailure(data) {
    $scope.loginProcessing = false;
    $scope.loginError = true;

    if(data.data.result.message === 'Your account has been suspended. Please contact techsupport@lisnr.com.') {
      $scope.loginError = true;
      $scope.login = false;
      $scope.suspended = true;
    } else if(data.data.result.message === 'Your user account has been disabled. Please contact your Account Administrator') {
      $scope.loginErrorMessage = data.data.result.message;
    } else {
      $scope.loginErrorMessage = 'There was an error logging in. Please try again.';
    }

    AuthenticationFactory.logout();
  }

  function getAccountSuccess(data) {
    $scope.user.accountstate = data.result[0].subscription_state;

    if($scope.user.accountstate === 'trial') {
      $scope.trial = true;
      setLoggedIn();
    } else if($scope.user.accountstate === 'expired_trial') {
      $scope.login = false;
      $scope.expiredTrial = true;
      $scope.expiredTrialDisplay = true;
    } else {
      setLoggedIn();
    }
  }

  function getAccountFailure(data) {
    $scope.loginProcessing = false;
    $scope.loginError = true;
    $scope.loginErrorMessage = 'There was an error logging in. Please try again.';

    AuthenticationFactory.logout();
  }

  $scope.changePasswordSubmit = function(validPassword, validPasswordRepeat) {
    if(!$scope.changePasswordProcessing) {
      if(!validPassword || !validPasswordRepeat) {
        $scope.changePasswordError = true;
        $scope.changePasswordErrorMessage = 'Please complete all required fields.';
      } else {
        var errorMessage = ChangePasswordFactory.validatePasswords([$scope.passwords.newPassword, $scope.passwords.newPasswordRepeat]);

        if (errorMessage) {
          passwordsInvalid(errorMessage);
        } else {
          passwordsValid();
        }
      }
    }
  }

  function passwordsValid() {
    $scope.changePasswordProcessing = true;
    $scope.passwords.oldPassword = $scope.user.password;
    ChangePasswordFactory.changePassword([$scope.passwords.oldPassword, $scope.passwords.newPassword]).then(changePasswordSuccess, changePasswordFailure);
  }

  function passwordsInvalid(message) {
    $scope.changePasswordError = true;
    $scope.changePasswordErrorMessage = message;
  }

  function changePasswordSuccess(data) {
    $scope.changePasswordProcessing = false;
    $scope.changePassword = false;

    if($scope.user.agreerequired) {
      $scope.userAgreement = true;
    } else {
      LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
    }
  }

  function changePasswordFailure(data) {
    $scope.changePasswordProcessing = false;
    $scope.changePasswordError = true;
    if(data.data.result.message) {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      $scope.changePasswordErrorMessage = message;
    } else {
      $scope.changePasswordErrorMessage = 'There was an error changing your password. Please try again.';
    }
  }

  $scope.agreeSubmit = function(validInitials) {
    if(!$scope.agreeProcessing) {
      if(!$scope.agreeCheckbox) {
        $scope.agreeError = true;
        $scope.agreeErrorMessage = 'Please check the box to accept the terms.';
        return;
      }

      if(!validInitials) {
        $scope.agreeError = true;
        $scope.agreeErrorMessage = 'Please provide your initials.';
        return;
      } else {
        $scope.agreeError = false;
        $scope.agreeErrorMessage = '';
      }

      $scope.agreeProcessing = true;
      LoginFactory.agreeV2($scope.agreeInfo.initials).then(agreeSuccess, agreeFailure);
    }
  }

  function agreeSuccess(data) {
    $scope.agreeProcessing = false;
    LoginFactory.getUserData().then(getUserDataSuccess, getUserDataFailure);
  }

  function agreeFailure(data) {
    $scope.agreeProcessing = false;
    $scope.agreeError = true;
    $scope.agreeErrorMessage = data.message;
  }

  function setLoggedIn() {
    AuthenticationFactory.set($scope.user.user_type, $scope.trial, $scope.expiredTrial, $scope.user.remember, $scope.user.username, $scope.user.email, $scope.user.isAdmin, $scope.user.userid, $scope.user.accountid, $scope.user.token, $scope.user.currentapp);

    if ($rootScope.redirectUnauthed) {
      $rootScope.redirectUnauthed = false;
      if ($rootScope.redirectUnauthedURL !== '/login/') {
        $location.url($rootScope.redirectUnauthedURL);
      } else {
        AuthenticationFactory.homepageRedirect();
      }
    } else {
      AuthenticationFactory.homepageRedirect();
    }
  }
});
