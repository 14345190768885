angular.module('FactoriesModule')
  .factory('ChurnZeroFactory', function ($rootScope) {
  
  var isBooted = false;
  
  function isLisnr(username) {
    return username.match(/@lisnr\.com/i) === null ? false : true;
  }
  
  function boot() {
    if(!isLisnr($rootScope.user.email) || environment !== 'master') {
      if(!isBooted) {
        if(ChurnZero) {
          isBooted = true;
          ChurnZero.push(['setAppKey', ChurnZeroAppKey]);
          ChurnZero.push(['setContact', $rootScope.user.accountid, $rootScope.user.email]);
        }
      }
    }
  }
  
  function shutdown() {
    if(isBooted) {
      isBooted = false;
      ChurnZero.push(['stop']);
    }
  }

  return {
    boot: boot,
    shutdown: shutdown
  }
});