angular.module('ControllersModule')
  .controller('ContentsController', function ($scope) {

    var contentsLinks = angular.element('.contents a');

    for(var i=0;i<contentsLinks.length;i++) {
      contentsLinks[i].addEventListener('click', contentLinkClickHandler);
    }

    function contentLinkClickHandler(event) {
      $('html, body').animate({
        scrollTop: $(event.currentTarget.dataset.link).offset().top
      }, 'fast');
    }
  
});